import React, { lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { Route as RouteEnum } from './types/Route';
import './i18n';

const Landing = lazy(() => import('./pages/Landing/Landing'));
const NotFound = lazy(() => import('./pages/NotFound/NotFound'));
const Blog = lazy(() => import('./pages/Blog/Blog'));
const Article = lazy(() => import('./pages/Blog/Article'));
const Podcast = lazy(() => import('./pages/Podcast/Podcast'));

export const Routes = () => (
	<Switch>
		<Route exact path="/" component={Landing} />
		<Route exact path="/about" component={Landing} />
		<Route exact path="/blog" component={Blog} />
		<Route exact path="/blog/:slug" component={Article} />
		<Route exact path="/podcast" component={Podcast} />
		<Route exact path="/makeitwork" component={Podcast} />
		<Route exact path="/imprint" component={Landing} />
		<Route exact path={RouteEnum.Support} component={Landing} />
		<Route exact path={RouteEnum.Contact} component={Landing} />
		<Route exact path={RouteEnum.NotFound} component={NotFound} />
		<Redirect to={RouteEnum.NotFound} />
	</Switch>
);
