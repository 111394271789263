import React, { Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes } from './Routes';
import './i18n';
import { ChakraProvider, Flex } from '@chakra-ui/react';
import { ErrorBoundary } from './components/layout/ErrorBoundary/ErrorBoundary';
import { AppUserContext, UserProvider } from './context/UserContext/UserContext';
import { AuthHandler } from './context/UserContext/AuthHandler';
import { SnackbarProvider } from 'notistack';
import { theme } from './theme';
import 'react-day-picker/dist/style.css';
import { AppSumoActivation } from './components/AppSumoActivation';
import { Spinner } from './components/UI/Spinner/Spinner';

export const App = () => (
	<ChakraProvider theme={theme}>
		<Suspense
			fallback={
				<Flex width="100vw" height="100vh" alignItems="center" justifyContent="center">
					<Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="brand.primary" size="xl" />
				</Flex>
			}
		>
			<SnackbarProvider maxSnack={4}>
				<UserProvider>
					<AuthHandler />
					<Router>
						<ErrorBoundary>
							<Routes />
						</ErrorBoundary>
					</Router>
					<AppUserContext.Consumer>
						{([{ user }]) => <>{user?.appSumo && <AppSumoActivation />}</>}
					</AppUserContext.Consumer>
				</UserProvider>
			</SnackbarProvider>
		</Suspense>
	</ChakraProvider>
);
