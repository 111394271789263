import { darken, mode, whiten } from '@chakra-ui/theme-tools';
import { StyleOptions } from '../types';

export const ButtonStyles = {
	baseStyle: {
		textTransform: 'capitalize',
		fontSize: 'md',
		_focus: {
			boxShadow: 'none',
			outline: 'none',
		},
		_hover: {
			textDecoration: 'none',
		},
	},
	sizes: {
		xl: {
			padding: '1.125rem 2.125rem',
		},
		sm: {
			h: 9,
			mt: '-1px',
			fontSize: '0.75rem',
		},
	},
	variants: {
		primary: (props: StyleOptions) => ({
			bg: 'brand.primary',
			color: 'white',
			fill: 'white',
			_hover: {
				bg: mode(darken('brand.primary', 10), whiten('brand.primary', 20))(props),
				_disabled: {
					bg: mode(darken('brand.primary', 10), whiten('brand.primary', 20))(props),
				},
			},
			_disabled: {
				opacity: 0.5,
				cursor: 'not-allowed',
			},
		}),
		primaryOutline: (props: StyleOptions) => ({
			bg: 'transparent',
			color: 'brand.primary',
			fill: 'brand.primary',
			border: '1px solid',
			borderColor: 'brand.primary',
			_hover: {
				color: mode(darken('brand.primary', 10), whiten('brand.primary', 20))(props),
				fill: mode(darken('brand.primary', 10), whiten('brand.primary', 20))(props),
				borderColor: mode(darken('brand.primary', 10), whiten('brand.primary', 20))(props),
			},
		}),
		success: (props: StyleOptions) => ({
			bg: 'brand.green',
			color: 'white',
			fill: 'white',
			_hover: {
				bg: mode(darken('brand.green', 10), whiten('brand.green', 20))(props),
			},
		}),
		successOutline: (props: StyleOptions) => ({
			bg: 'transparent',
			border: '1px solid',
			borderColor: 'brand.green',
			color: 'brand.green',
			fill: props.colorMode === 'dark' ? 'gray.100' : 'brand.green',
			_hover: {
				bg: 'transparent',
				color: mode(darken('brand.green', 10), whiten('brand.green', 20))(props),
				fill: mode(darken('brand.green', 10), whiten('brand.green', 20))(props),
				borderColor: mode(darken('brand.green', 10), whiten('brand.green', 20))(props),
			},
		}),
		danger: (props: StyleOptions) => ({
			bg: 'brand.red.primary',
			color: 'white',
			fill: 'white',
			_hover: {
				bg: mode(darken('brand.red.primary', 10), whiten('brand.red.primary', 20))(props),
			},
		}),
		dangerOutline: (props: StyleOptions) => ({
			bg: 'transparent',
			border: '1px solid',
			borderColor: 'brand.red.primary',
			color: 'brand.red.primary',
			fill: props.colorMode === 'dark' ? 'gray.100' : 'brand.red.primary',
			_hover: {
				bg: 'transparent',
				color: mode(darken('brand.red.primary', 10), whiten('brand.red.primary', 20))(props),
				fill: mode(darken('brand.red.primary', 10), whiten('brand.red.primary', 20))(props),
				borderColor: mode(darken('brand.red.primary', 10), whiten('brand.red.primary', 20))(props),
			},
		}),
		gray: (props: StyleOptions) => ({
			bg: 'gray.300',
			color: 'white',
			fill: 'white',
			_hover: {
				bg: mode(darken('gray.300', 10), whiten('gray.300', 20))(props),
			},
		}),
		grayOutline: (props: StyleOptions) => ({
			bg: 'transparent',
			border: '1px solid',
			borderColor: 'gray.300',
			color: 'gray.300',
			fill: 'gray.300',
			_hover: {
				bg: 'transparent',
				color: mode(darken('gray.300', 10), whiten('gray.300', 20))(props),
				fill: mode(darken('gray.300', 10), whiten('gray.300', 20))(props),
				borderColor: mode(darken('gray.300', 10), whiten('gray.300', 20))(props),
			},
		}),
	},
	defaultProps: {
		size: 'md',
		variant: 'primary',
	},
};
