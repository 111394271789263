const translations = {
	pageTitle: {
		'/': 'Epek',
		'/workspace': 'Alle Projekte',
		'/my-projects': 'Meine Projekte',
		'/settings': 'Alle Einstellungen',
	},
	common: {
		ApplicationName: 'EPEK',
		Add: 'Hinzufügen',
		Cancel: 'Abbrechen',
		Login: 'Anmelden',
		DueDate: 'Fälligkeitsdatum',
		URL: 'URL',
		Create: 'Erstellen',
		Clone: 'Klonen',
		Save: 'Speichern',
		Update: 'Aktualisieren',
		Logout: 'Abmelden',
		And: 'und',
		None: 'Keine',
		Yes: 'Ja',
		No: 'Nein',
	},
	translation: {
		Common: {
			ApplicationName: 'EPEK',
			Add: 'Hinzufügen',
			Cancel: 'Abbrechen',
			Login: 'Anmelden',
			Logout: 'Abmelden',
			Error: 'Fehler!',
			Save: 'Speichern',
			Loading: 'Lädt...',
			Name: 'Name',
			Email: 'E-Mail',
			Password: 'Passwort',
			MobileMessage:
				'P.S. epek ist nicht für Mobilgeräte konzipiert - wir empfehlen, es auf Ihrem Laptop oder Desktop-Computer zu verwenden.',
			AppSumo: 'AppSumo',
			Continue: 'Fortsetzen',

			Filters: {
				ByStatus: 'Nach Status sortieren',
				ByDueDate: 'Nach Fälligkeitsdaten sortieren',
				ByRank: 'Nach Prioritäten sortieren',
			},
		},
		TimeScale: {
			900: '3 Tage',
			400: 'Woche',
			200: 'Zwei Wochen',
			100: 'Monat',
			50: 'Zwei Monate',
			17: 'Sechs Monate',
			8: 'Jahr',
			Short: {
				900: '3T',
				400: '1W',
				200: '2W',
				100: '1M',
				50: '2M',
				17: '6M',
				8: '1J',
			},
		},
		Workspace: {
			SwitcherTitle: 'Arbeitsbereich(e)',
			Projects: 'Projekte',
		},
		Settings: {
			Title: 'Alle Einstellungen',
			UserSettings: 'Profileinstellungen',
			Collaborators: 'Mitarbeiter des Arbeitsbereichs',
			WorkspaceSettings: 'Einstellungen des Arbeitsbereichs',
			Payment: 'Abrechnung',
			AppSumo: 'AppSumo',
			NameLabel: 'Ihr Name',
		},
		AppSumo: {
			Errors: {
				AlreadyActivated:
					'Code wurde bereits verwendet. Bitte kontaktieren Sie unser Support-Team unter <a href="{{contact}}">{{contact}}</a>',
				InvalidCode: 'Aktueller Code ist ungültig',
			},
			Columns: {
				code: 'Code',
				startsAt: 'Beginnt am',
				endsAt: 'Endet am',
				status: 'Status',
			},
			ThankYou: 'Danke für die Nutzung von AppSumo',
			ThankYouDescription:
				'AppSumo-Plan wurde aktiviert und ermöglicht Ihnen die Nutzung von bis zu 10 Editoren für ein Jahr.',
			Activate: 'Aktivieren Sie hier Ihr AppSumo-Angebot',
			ActivatePlaceholder: 'Geben Sie den Code ein, den Sie bei AppSumo gekauft haben',
			Increase: 'Erhöhen Sie das Editor-Limit mit Ihrem AppSumo-Top-up-Code',
			ActivateSubmit: 'Aktivieren',
			Note: 'HINWEIS: Nach Ablauf Ihres Plans werden Sie automatisch auf den kostenlosen Plan herabgestuft. Sie können jederzeit mehr Editoren über den Team-Plan hinzufügen, wenn Sie möchten.',
			IncreaseSubmit: 'Erhöhen',
			History: 'Verlauf',
			DealDescription:
				'Unser Partnervertrag mit AppSumo ist beendet. Ihre eingelösten Codes funktionieren bis zu ihrem Ablaufdatum.',
			CTA: 'Möchten Sie mehr wissen oder sofort kaufen?',
			PricingCTA: 'Angebot für begrenzte Zeit!',
			DealPageText: 'Holen Sie sich das Angebot bei AppSumo',
			FAQHint: 'Haben Sie Fragen zum AppSumo-Angebot? Schauen Sie sich unsere FAQ dazu an',
		},
		Support: {
			Title: 'Wie können wir helfen?',
			GeneralSupport: 'Allgemein',
			BillingSupport: 'Preise',
			Contact: 'Kontakt',
		},
		Footer: {
			About: 'Über',
			Legal: 'Rechtliches',
		},
		Navigation: {
			Profile: 'Profil',
			MyProjects: 'Meine Projekte',
			Workspaces: 'Arbeitsbereiche',
		},
		Status: {
			TODO: 'Zu Erledigen',
			DONE: 'Erledigt',
			BLOCKED: 'Blockiert',
			IN_REVIEW: 'In Überprüfung',
			IN_PROGRESS: 'In Bearbeitung',
			Label: 'Status',
		},
		OnBoarding: {
			GetStarted: 'Lassen Sie uns anfangen.',
			SetUpYourTeam: 'Benennen Sie Ihren Arbeitsbereich',
			SetUpYourName: 'Wie sollen wir Sie nennen?',
			InviteMates: 'Laden Sie einige Teamkollegen ein',
			AddEmails: 'Fügen Sie eine E-Mail-Adresse hinzu',
			InviteMatesDescription: 'Sie können Ihr Team später auch über die Arbeitsbereichseinstellungen einladen.',
			Continue: 'Einrichten und fortfahren',
			SendInvites: 'Einladungen senden',
			Skip: 'Jetzt überspringen',
			AllSet: 'Alles ist bereit.',
			BuildYourOwn: 'Machen Sie Ihre Pläne!',
			BuildYourOwnDescription:
				'In Ihrem Arbeitsbereich finden Sie ein paar Beispielprojekte. Schauen Sie sie sich zuerst an, um zu sehen, wie Ihre Pläne aussehen könnten, und um eine Vorstellung davon zu bekommen, was epek für Sie tun kann!',
			TeamName: 'Wie sollen wir Ihren Arbeitsbereich nennen?',
			YourName: 'Wie lautet bitte Ihr vollständiger Name?',
		},
		Organization: {
			NameLabel: 'Organisation',
			IdLabel: 'Id',
			CreatedLabel: 'Erstellt',
			Update: {
				WorkspaceSettings: 'Arbeitsbereichseinstellungen',
				NameLabel: 'Name des Arbeitsbereichs',
				LogoLabel: 'Bild des Arbeitsbereichs',
				LogoChanged:
					'Sie haben das Bild des Arbeitsbereichs geändert, denken Sie daran, auf Aktualisieren zu drücken.',
			},
			Delete: {
				Title: 'GEFAHRENBEREICH: Löschung des Arbeitsbereichs',
				Request:
					'Wenn Sie möchten, dass wir diesen Arbeitsbereich und alles darin löschen, schreiben Sie uns einfach eine E-Mail an support@epekworks.com und wir erledigen das für Sie.',
				InfoToCopy:
					'Bitte kopieren Sie die folgenden Informationen und fügen Sie sie in Ihre Anfrage-E-Mail ein, damit das Support-Team mit der Löschung fortfahren kann.',
				Warning:
					'Die Löschung des Arbeitsbereichs entfernt alle damit verbundenen und darin enthaltenen Informationen und kann nicht rückgängig gemacht werden. Einige Informationen können aufgrund gesetzlicher Aufbewahrungsfristen gemäß der EU-DSGVO weiterhin gespeichert werden. Sie werden weiterhin auf andere Arbeitsbereiche zugreifen können, deren Mitglied Sie sind.',
			},
			Users: {
				Access: 'Alle Personen mit Zugang',
				Pending: 'Ausstehende Einladungen',
				Invite: 'Per E-Mail zum Arbeitsbereich einladen',
				SendInvites: 'Einladungen senden',
				SendInvitesError: 'Existiert bereits in dieser Organisation',
				Emails: 'E-Mail-Adressen hier eingeben',
				NoUsers: 'Sie haben noch keine Mitarbeiter.',
				NoUsersToShow: 'In diesem Abschnitt gibt es keine Benutzer zu zeigen.',
				FreeUsers: 'Anzahl der Editoren',
				FreeUsersInfo:
					'Dies ist die Gesamtanzahl der genutzten Editoren aus den verfügbaren Editoren Ihres aktuellen Abonnements. Die Editoranzahl umfasst den Besitzer des Arbeitsbereichs und die Administratoren des Arbeitsbereichs.',
				NoPendingUsers: 'Sie haben keine ausstehenden Einladungen.',
				Roles: {
					Admin: 'Administrator',
					Editor: 'Editor',
					Viewer: 'Betrachter',
					Member: 'Mitglied',
					Owner: 'Besitzer',
				},
				Actions: {
					MakeAdmin: {
						Description: 'Administratorrechte gewähren',
						Confirm: 'Nur zur Sicherheit, sind Sie sicher?',
						Content: 'Diese Person wird Administratorrechte für den Arbeitsbereich erhalten!',
					},
					RemoveAdmin: {
						Description: 'Administratorrechte entziehen',
						Confirm: 'Nur zur Sicherheit, sind Sie sicher?',
						Content:
							'Diese Person wird keine Administratorrechte für den Arbeitsbereich mehr haben! Sie wird weiterhin Mitglied Ihres Arbeitsbereichs sein.',
					},
					MakeEditor: {
						Description: 'Editorrechte gewähren',
						Confirm: 'Nur zur Sicherheit, sind Sie sicher?',
						Content: 'Diese Person wird Editorrechte für das Projekt erhalten!',
					},
					RemoveEditor: {
						Description: 'Editorrechte entziehen',
						Confirm: 'Nur zur Sicherheit, sind Sie sicher?',
						Content: 'Diese Person wird keine Editorrechte für das Projekt mehr haben!',
					},
					RemoveUser: {
						Description: 'Vom Arbeitsbereich entfernen',
						Confirm: 'Nur zur Sicherheit, sind Sie sicher?',
						Content:
							'Diese Person wird aus Ihrem Arbeitsbereich entfernt. Sie wird KEINEN Zugang mehr zum Arbeitsbereich haben! Sie können sie jederzeit wieder einladen, wenn Sie möchten.',
					},
					RemovePendingUser: {
						Description: 'Einladung widerrufen',
						Confirm: 'Nur zur Sicherheit, sind Sie sicher?',
						Content:
							'Die Einladung wird widerrufen. Sie können sie jederzeit wieder einladen, wenn Sie möchten.',
					},
					InviteUsers: {
						Confirm: 'Woohoo! Diese Party fängt gerade erst an!',
						Content:
							'{{users}} können an Ihrem Arbeitsbereich teilnehmen, sobald sie ihr Konto erstellt haben. Drücken Sie den Knopf, um die Einladungen zu senden!',
					},
				},
				Errors: {
					MaxFreeEditors: {
						Title: 'Sie haben die maximale Anzahl an Editoren im GRATIS-Plan erreicht',
						Content: 'Wechseln Sie zum TEAM-Plan, um mehr Editoren zu haben',
						OkText: 'Auf Epek TEAM-Plan upgraden',
						CancelText: 'Später',
					},
					MaxFreeProjects: {
						Title: 'Sie haben die maximale Anzahl an Projekten im GRATIS-Plan erreicht',
						Content: 'Wechseln Sie zum TEAM-Plan, um mehr Projekte zu haben',
						OkText: 'Auf Epek TEAM-Plan upgraden',
						CancelText: 'Später',
					},
				},
			},
		},
		Project: {
			Timeline: {
				TimelineView: 'Zeitstrahlansicht',
				ColumnView: 'Spaltenansicht',
				ListView: 'Listenansicht',
				Backlog: 'Backlog',
				Today: 'Heute',
				ShowTasks: 'Aufgaben anzeigen',
				TasksVisible: 'Sichtbare Aufgaben',
			},
			Item: {
				AddImagesFiles: 'Bilder & Dateien hinzufügen',
				AddLink: 'Link hinzufügen',
			},
			Milestone: {
				Header: 'Meilenstein',
				Create: {
					Title: 'Einen Meilenstein hinzufügen',
					TitleLabel: 'Meilensteintitel... (erforderlich)',
					DescriptionLabel: 'Beschreibung...',
					StartDateLabel: 'Startdatum',
					EndDateLabel: 'Fälligkeitsdatum',
					TagsLabel: 'Tags...',
					CreateButtonLabel: 'Einen Meilenstein hinzufügen',
					TasksHint: 'Nachdem du den Meilenstein erstellt hast, kannst du hier Aufgaben hinzufügen!',
				},
				Details: {
					Title: 'Meilensteindetails',
				},
				Clone: {
					Title: 'Meilenstein klonen',
					UnassignAssignees: 'Aufgaben und Teilaufgaben von Zuweisungen lösen',
					ResetStatuses: 'Alle Status auf ZU ERLEDIGEN zurücksetzen',
					TasksToBacklog: 'Geklonte Aufgaben in den Backlog verschieben',
					WithComments: 'Kommentare einbeziehen',
				},
				Tasks: 'Aufgaben',
				Tags: 'Tags',
				Task: 'Neue Aufgabe hinzufügen',
				ListOfTasks: 'Liste der Aufgaben',
				NoTasks: 'Dieser Meilenstein hat keine Aufgaben zu verzeichnen',
				NoTasksHint:
					'Aufgaben sind einfach Dinge, die du tun musst, um diesen Meilenstein oder dieses Ziel zu erreichen. Denke an sie als Aufgaben, die du erzählen würdest, wenn du fertig bist, z.B. "Meine Webseite veröffentlichen", "Kontaktinformationen aktualisieren", "das Essay zur Überprüfung schicken"',
				DateRange: 'Fälligkeitsdatum',
				StatusLabel: 'Status',
				MoreInfoLabel: 'Links',
				ImagesLabel: 'Bilder',
				StartDate: '(optional) Startdatum',
				EndDate: 'Fällig',
				Duration: 'Dauer',
			},
			Backlog: {
				Header: 'Backlog',
				Hint: 'Dein Backlog enthält alle Aufgaben (Dinge, die zu tun sind), die noch keinem spezifischen Meilenstein zugeordnet sind. Denke daran wie an einen Parkplatz für Ideen. Füge sie einfach hier hinzu und später kannst du sie in spezifische Meilensteine verschieben oder sie vom Meilenstein zurück in den Backlog senden!',
				MilestonePlanningHint:
					'Du kannst deine Meilensteine planen, indem du einfach eine Aufgabe vom Backlog zu einem Meilenstein ziehst oder Aufgaben zwischen Meilensteinen und dem Backlog hin und her bewegst. Probier es aus!',
				Create: {
					Title: 'Einen Meilenstein hinzufügen',
					TitleLabel: 'Meilensteintitel... (erforderlich)',
					DescriptionLabel: 'Beschreibung...',
					StartDateLabel: 'Startdatum',
					EndDateLabel: 'Fälligkeitsdatum',
					TagsLabel: 'Tags...',
					CreateButtonLabel: 'Einen Meilenstein hinzufügen',
					TasksHint: 'Nachdem du den Meilenstein erstellt hast, kannst du hier Aufgaben hinzufügen!',
				},
				Details: {
					Title: 'Sag etwas zu deinem Backlog',
				},
				Tasks: 'Aufgaben',
				Task: 'Neue Aufgabe hinzufügen',
				DateRange: 'Fälligkeitsdatum',
				StatusLabel: 'Status',
				MoreInfoLabel: 'Links',
				StartDate: '(optional) Startdatum',
				EndDate: 'Fällig',
				Duration: 'Dauer',
			},
			Project: {
				Create: {
					Title: 'Neuer Projektname',
					Description: 'Projektbeschreibung',
				},
				Clone: {
					Title: 'Projekt klonen',
					UnassignAssignees: 'Aufgaben und Teilaufgaben von Zuweisungen lösen',
					ResetStatuses: 'Alle Status auf ZU ERLEDIGEN zurücksetzen',
					TasksToBacklog: 'Alle Aufgaben in den Backlog verschieben',
					WithComments: 'Kommentare einbeziehen',
				},
				AddNewProject: 'Neues Projekt hinzufügen',
				Header: 'Projekt',
				NameLabel: 'Projektname',
				MilestoneList: 'Alle Meilensteine',
				SelectOpenMilestone: 'Einen der offenen Meilensteine auswählen',
			},
			Zoom: 'Skala:',
			Task: {
				Header: 'Aufgabe',
				Create: {
					Title: 'Eine Aufgabe hinzufügen',
					TitleLabel: 'Titel... (erforderlich)',
					DescriptionLabel: 'Beschreibung...',
					StartDateLabel: 'Startdatum',
					EndDateLabel: 'Fällig',
					TagsLabel: 'Tags...',
				},
				Clone: {
					Title: 'Aufgabe klonen',
					UnassignAssignees: 'Aufgabe und Teilaufgaben von Zuweisungen lösen',
					ResetStatuses: 'Status(e) auf ZU ERLEDIGEN zurücksetzen',
					TasksToBacklog: 'Geklonte Aufgabe in den Backlog verschieben',
					WithComments: 'Kommentare einbeziehen',
				},
				ToBacklog: 'Zurück zum Backlog...',
				MoveTask: 'In einen anderen Meilenstein verschieben...',
				CloneTask: 'Aufgabe klonen',
				DeleteTask: 'Aufgabe löschen',
				DateRange: 'Fällig',
				StatusLabel: 'Status',
				MoreInfoLabel: 'Links',
				AssigneeLabel: 'Zuweisen',
				Subtasks: 'Teilaufgaben',
				Subtask: 'Neue Teilaufgabe hinzufügen',
				ListOfSubtasks: 'Liste der Teilaufgaben',
				NoSubtasks: 'Diese Aufgabe hat noch keine Teilaufgaben',
				NoSubtasksHint:
					'Teilaufgaben sind die Details innerhalb der Aufgaben, z.B. wenn die Aufgabe "das Essay zur Überprüfung schicken" war, könnten die Teilaufgaben "Stellen Sie sicher, dass das Format korrigiert ist", "Fügen Sie Fußzeilen mit Seitenzahlen hinzu", "Fügen Sie Referenzen am Ende hinzu" sein',
				MoveHeader: 'Verschieben: ',
				CurrentMilestone: 'Von:',
				NewMilestone: 'Zu:',
			},
			Subtask: {
				Header: 'Teilaufgabe',
				CloneSubtask: 'Teilaufgabe klonen',
				DeleteSubtask: 'Teilaufgabe löschen',
				Create: {
					Title: 'Neue Teilaufgabe hinzufügen',
					TitleLabel: 'Titel... (erforderlich)',
					DescriptionLabel: 'Beschreibung...',
					StartDateLabel: 'Startdatum',
					EndDateLabel: 'Fällig',
					TagsLabel: 'Tags...',
					UrlLabel: 'URL',
				},
				Clone: {
					Title: 'Teilaufgabe klonen',
					UnassignAssignees: 'Zuweisungen aufheben',
					ResetStatuses: 'Status auf ZU ERLEDIGEN zurücksetzen',
					WithComments: 'Kommentare einbeziehen',
				},
				DateRange: 'Fällig',
				StatusLabel: 'Status',
				MoreInfoLabel: 'Links',
				AssigneeLabel: 'Zuweisen',
			},
		},
		Plan: {
			InviteUsers:
				'Laden Sie mehr Personen aus dem Kollaborationseinstellungsbildschirm Ihres Arbeitsbereichs ein!',
		},
		SignUp: {
			Title: 'Beginnen Sie mit Epek',
			Login: 'Einloggen',
			Register: 'ANMELDEN',
			ResentVerification: 'Verifizierung erneut senden',
			PasswordRules:
				'Unsere Passwortrichtlinie: mindestens 8 Zeichen, einschließlich Zahlen. Wir empfehlen eine Mischung aus Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen.',
		},
		ResendVerification: {
			Title: 'Überprüfen Sie Ihr Konto',
			Button: 'Verifizierungs-E-Mail senden',
		},
		SignUpSuccess: {
			Title: 'E-Mail-Adresse überprüfen',
			TextPartOne: 'Wir haben Ihnen gerade eine Verifizierungs-E-Mail an die Adresse geschickt: ',
			TextPartTwo:
				'. Bitte überprüfen Sie Ihren Posteingang und bestätigen Sie Ihre E-Mail-Adresse. Danach sollten Sie sich einloggen können.',
			SignupAgain: 'Erneut anmelden',
		},
		Login: {
			Title: 'Willkommen zurück',
			TitleSimple: 'Willkommen',
			SignUp: 'Anmelden',
			Login: 'Einloggen',
			LoginWithGoogle: 'Mit Google fortfahren',
			LoginWithMagic: 'Mit Magic Link fortfahren',
			TypePassword: 'Bevorzugen Sie, das Passwort einzugeben?',
			PreferMagicLink: 'Bevorzugen Sie Magic Links?',
			MagicEmailSentTitle: 'Ist das nicht magisch?',
			MagicEmailSentTextOne: 'Ihr Magic Link ist unterwegs zu ',
			MagicEmailSentTextTwo: 'Klicken Sie einfach auf den Link, um sich einzuloggen und Epek zu genießen!',
			ForgotPassword: 'Passwort vergessen?',
			CatchPhrase: 'Verlieren Sie nie Ihre Ziele aus den Augen!',
			NewToEpek: 'Neu bei Epek? Registrieren und einloggen mit Google oder Magic Link in einem Schritt!',
		},
		ForgotPassword: {
			Title: 'Passwort-Reset anfordern',
			Text: 'Bitte geben Sie die E-Mail-Adresse ein, mit der Sie sich angemeldet haben, und wir senden Ihnen den Link, um Ihr Passwort zurückzusetzen.',
			Request: 'Mir die E-Mail senden',
		},
		ForgotPasswordSuccess: {
			Title: 'Link zum Zurücksetzen des Passworts gesendet!',
			Text: 'Ihr Link zum Zurücksetzen des Passworts sollte jetzt in Ihrem Posteingang sein. Wir sind hier, um zu helfen, wenn Sie weitere Unterstützung benötigen!',
		},
		ResetPassword: {
			Title: 'Bitte geben Sie ein neues Passwort ein',
			NewPassword: 'NeuesPasswort',
			ConfirmNewPassword: 'NeuesPasswortBestätigen',
			Request: 'Passwort aktualisieren',
			PasswordShouldMatch: 'Die Passwörter stimmen nicht überein',
		},
		ResetPasswordSuccess: {
			Title: 'Erfolg!',
			Text: 'Ihr Passwort wurde erfolgreich zurückgesetzt. Sie können sich jetzt mit Ihrem neuen Passwort in Ihrem Arbeitsbereich anmelden.',
		},
		VerifyEmail: {
			ErrorTitle: 'Das hat nicht funktioniert...',
			ErrorText: 'Ihr Konto konnte nicht bestätigt werden, hoffentlich erklärt die folgende Fehlermeldung warum:',
			SuccessTitle: 'Konto bestätigt!',
			SuccessText:
				'Danke, dass Sie auf den Link geklickt haben! Ihre E-Mail-Adresse wurde verifiziert und Sie können loslegen.',
		},
		Payments: {
			Tabs: {
				Subscription: 'Abonnement',
				CostCalculator: 'Kostenrechner',
				PaymentInfo: 'Zahlungsinformationen',
				Invoices: 'Rechnungen',
			},
			Subscribe: 'Upgrade',
			Unsubscribe: 'Downgrade',
			UnsubscribePrompt: {
				Title: 'Sind Sie sicher, dass Sie vom Epek TEAM-Plan herunterstufen wollen?',
				Content:
					'Das Herunterstufen vom Epek TEAM-Plan wird alle zusätzlichen Editoren am Ende des aktuellen Abrechnungszeitraums zu Betrachtern umwandeln, wobei NUR drei Mitglieder des Arbeitsbereichs als Editoren verbleiben. Sie können die Editierrechte jederzeit ändern, wenn Sie möchten.',
			},
			BillingPeriod: 'Der aktuelle Abrechnungszeitraum läuft von <1>{{from}}</1> bis <3>{{to}}</3>',
			Subscription: {
				Plan: 'Epek TEAM',
				PlanDescription:
					'Unbegrenzte Betrachter, {{freeEditors}} Editoren + ${{pricePerEditor}} für jeden zusätzlichen Editor',
				CancelledInfo: 'Das Abonnement ist gekündigt. Und wird am Ende des aktuellen Zeitraums enden.',
				BillingEndDate: 'Abrechnung endete am {{endDate}}',
			},
			EstimatedBill: 'Geschätzte monatliche Rechnung ${{value}}',
			MissingPaymentMethod: 'Bitte fügen Sie zuerst eine Zahlungsmethode hinzu',
			Errors: {
				PaymentMethodFetch: 'Fehler beim Abrufen der Zahlungsinformationen',
				PaymentMethodChange: 'Fehler beim Ändern der Zahlungsmethode',
				PaymentMethodAdd: 'Fehler beim Hinzufügen der Zahlungsmethode',
				InvoicesFetch: 'Fehler beim Abrufen der Rechnungsliste',
				BillingDetailsChange: 'Fehler beim Aktualisieren der Abrechnungsdetails',
			},
			PaymentMethod: 'Zahlungsmethode',
			PaymentMethodInfo: '{{brand}} endet mit {{last4}}',
			PaymentMethodEdit: 'Zahlungsmethode ändern',
			PaymentMethodAdd: 'Zahlungsmethode hinzufügen',
			PaymentMethodUpdated: 'Zahlungsmethode erfolgreich aktualisiert',
			BillingDetailsUpdate: 'Aktualisieren',
			PersonalUse: 'Persönlicher Gebrauch',
			BillingName: 'Abrechnungsname',
			BillingAddress: 'Abrechnungsadresse',
			BillingTaxNumber: 'Umsatzsteuer-Identifikationsnummer',
			BillingCountry: 'Land',
			BillingDetailsChanged: 'Abrechnungsdetails erfolgreich aktualisiert',
			InvoiceTable: {
				createdAt: 'Datum',
				status: 'Status',
				total: 'Gesamt',
				pdfUrl: 'PDF herunterladen',
			},
		},
		GenericPageError: {
			Title: 'Etwas ist schief gelaufen',
			Text: 'Bitte versuchen Sie, die Seite neu zu laden oder zur Startseite zurückzukehren',
			Button: 'Neu laden',
		},
		Messages: {
			URLCopy: {
				Done: 'Link wurde in die Zwischenablage kopiert',
			},
			History: {
				create: 'erstellt',
				update: 'aktualisiert',
				delete: 'gelöscht',
				clone: 'geklont',
				move: 'verschoben',
				assign: 'Zuweisung geändert',
				status: 'Status geändert',
				public: 'öffentlich gemacht',
				not_public: 'privat gemacht',
			},
			Filters: {
				ByStatusError: 'Deaktivieren Sie "Sortieren nach Fälligkeitsdatum", um Aufgabenprioritäten zu ändern',
			},
			Subtask: {
				Created: 'Unteraufgabe wurde erstellt',
				CreatedError: 'Fehler beim Erstellen der Unteraufgabe',
				Cloned: 'Unteraufgabe wurde geklont',
				ClonedError: 'Fehler beim Klonen der Unteraufgabe',
				Updated: 'Unteraufgabe wurde aktualisiert',
				UpdatedError: 'Fehler beim Aktualisieren der Unteraufgabe',
				Deleted: 'Unteraufgabe wurde entfernt',
				DeletedError: 'Fehler beim Entfernen der Unteraufgabe',
				NoTitleError: 'Titel der Unteraufgabe ist erforderlich',
				NotFound: 'Unteraufgabe nicht gefunden',
			},
			Task: {
				Moved: 'Aufgabe wurde verschoben',
				MovedError: 'Fehler beim Verschieben der Aufgabe',
				Created: 'Aufgabe wurde erstellt',
				CreatedError: 'Fehler beim Erstellen der Aufgabe',
				Cloned: 'Aufgabe wurde geklont',
				ClonedError: 'Fehler beim Klonen der Aufgabe',
				Updated: 'Aufgabe wurde aktualisiert',
				UpdatedError: 'Fehler beim Aktualisieren der Aufgabe',
				Deleted: 'Aufgabe wurde entfernt',
				DeletedError: 'Fehler beim Entfernen der Aufgabe',
				NoTitleError: 'Titel der Aufgabe ist erforderlich',
				SameMilestone: 'Aufgabe ist bereits im Ziel-Meilenstein',
				NotFound: 'Aufgabe nicht gefunden',
			},
			Milestone: {
				Created: 'Meilenstein wurde erstellt',
				CreatedError: 'Fehler beim Erstellen des Meilensteins',
				Cloned: 'Meilenstein wurde geklont',
				ClonedError: 'Fehler beim Klonen des Meilensteins',
				Updated: 'Meilenstein wurde aktualisiert',
				UpdatedError: 'Fehler beim Aktualisieren des Meilensteins',
				Deleted: 'Meilenstein wurde entfernt',
				DeletedError: 'Fehler beim Entfernen des Meilensteins',
				NoTitleError: 'Titel des Meilensteins ist erforderlich',
				NotFound: 'Meilenstein nicht gefunden',
			},
			Folder: {
				Created: 'Neuer Ordner wurde am Ende der Liste hinzugefügt',
			},
			Project: {
				Created: 'Projekt wurde erstellt',
				CreatedError: 'Fehler beim Erstellen des Projekts',
				Cloned: 'Projekt wurde geklont',
				ClonedError: 'Fehler beim Klonen des Projekts',
				Updated: 'Projekt wurde aktualisiert',
				UpdatedError: 'Fehler beim Aktualisieren des Projekts',
				Deleted: 'Projekt wurde entfernt',
				DeletedError: 'Fehler beim Entfernen des Projekts',
				NoTitleError: 'Titel des Projekts ist erforderlich',
				NotFoundError: 'Projekt konnte nicht gefunden werden',
				ViewerMessage:
					'Sie haben nur LESERECHTE in diesem Projekt. Bitte sprechen Sie mit dem Besitzer des Arbeitsbereichs oder den Administratoren, um Bearbeitungsrechte zu erhalten, falls nötig.',
				MashupMessage:
					'Hinweis: Die Mix/Mashup-Ansicht ist nur zur Ansicht, daher können Sie die Meilensteine oder Elemente darin nicht bearbeiten.',
			},
			UserDetails: {
				Updated: 'Ihre Details wurden aktualisiert',
				UpdatedError: 'Fehler beim Aktualisieren Ihrer Details',
				NoTitleError: 'Ihr Name ist erforderlich',
			},
			UserWorkspace: {
				Invite: 'Ihre Einladungen werden sofort gesendet',
				InviteWithoutEmails: 'Bitte fügen Sie die E-Mail-Adressen der Eingeladenen hinzu',
				Leave: 'Sie haben diesen Arbeitsbereich verlassen',
				LeaveError: 'Fehler beim Verlassen des Arbeitsbereichs',
				LeaveOwnOrgError: 'Sie können Ihren eigenen Arbeitsbereich nicht verlassen',
				Updated: 'Ihr Arbeitsbereich wurde aktualisiert',
				UpdatedError: 'Fehler beim Aktualisieren des Namens oder Logos des Arbeitsbereichs',
				NoTitleError: 'Name des Arbeitsbereichs ist erforderlich',
			},
		},
	},
};

export default translations;
