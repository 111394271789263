import { AuthUser } from '../../types/AuthUser';
import * as UserActionTypes from './constants';
import { makeAction } from '../../types/makeAction';
import { Organization } from '../../types/Organization';
import { UserDetails } from '../../types/UserDetails';

export const setUser = (user: AuthUser) => {
	return makeAction(UserActionTypes.SET_USER, {
		user,
	});
};

export const setActiveOrganization = (organization?: Organization) => {
	return makeAction(UserActionTypes.SET_ACTIVE_ORGANIZATION, {
		organization,
	});
};

export const setOrganizations = (organizations: Organization[]) => {
	return makeAction(UserActionTypes.SET_ORGANIZATIONS, {
		organizations,
	});
};

export const setUserDetails = (userDetails: UserDetails) => {
	return makeAction(UserActionTypes.SET_USER_DETAILS, {
		userDetails,
	});
};

export const setStatusFilter = (byStatus: boolean) => {
	localStorage.setItem('FILTER_BY_STATUS', byStatus ? '1' : '0');
	return makeAction(UserActionTypes.SET_STATUS_FILTER, {
		byStatus,
	});
};

export const setDueDateFilter = (byDueDate: boolean) => {
	localStorage.setItem('FILTER_BY_DUE_DATE', byDueDate ? '1' : '0');
	return makeAction(UserActionTypes.SET_DUE_DATE_FILTER, {
		byDueDate,
	});
};

export const setUserLoaded = (userLoaded?: boolean) => {
	return makeAction(UserActionTypes.SET_USER_LOADED, {
		userLoaded: !!userLoaded,
	});
};

export const deleteUser = () => {
	return makeAction(UserActionTypes.DELETE_USER);
};
