export const SwitchStyles = {
	baseStyle: {
		track: {
			_focus: {
				boxShadow: 0,
				outline: 'none',
			},
		},
	},
};
