export const CheckboxStyles = {
	baseStyle: {
		control: {
			w: '100%',
			transitionProperty: 'box-shadow',
			transitionDuration: 'normal',
			outline: 'none',
			border: '2px solid',
			borderRadius: 'sm',
			borderColor: 'inherit',
			color: 'white',
			_checked: {
				bg: 'brand.primary',
				borderColor: 'brand.primary',
				color: 'white',
				_hover: {
					bg: 'undefined.600',
					borderColor: 'undefined.600',
				},
				_disabled: {
					borderColor: 'gray.300',
					bg: 'gray.300',
					color: 'gray.500',
				},
			},
			_disabled: {
				bg: 'gray.100',
				borderColor: 'gray.100',
			},
			_focus: {
				outline: 'none',
				// boxShadow: 'outline'
				boxShadow: 'none',
			},
			_invalid: {
				borderColor: 'brand.danger.primary',
			},
		},
	},
	sizes: {},
	variants: {},
	defaultProps: {
		size: 'lg',
	},
};
