import { AxiosResponse } from 'axios';
import api from './base';
import { Task, TaskClone } from '../types/Task';
import { ProjectFilters } from '../types/Project';
import { EntryHistory } from '../types/EntryHistory';

export const create = (
	milestoneId: string,
	story: Omit<Task, 'id'> & { statusUpdate?: boolean }
): Promise<AxiosResponse<Task>> => api.put('/story', { milestoneId, ...story });

export const remove = (storyId: string): Promise<AxiosResponse<Task>> => api.delete(`/story/${storyId}`);

export const update = (
	storyId: string,
	story: Partial<Omit<Task, 'id'>> & { statusUpdate?: boolean }
): Promise<AxiosResponse<Task>> => api.post(`/story/${storyId}`, { ...story });

export const get = (storyId: string, filters?: ProjectFilters): Promise<AxiosResponse<Task>> =>
	api.get(`/story/${storyId}`, {
		params: filters,
	});

export const history = (storyId: string): Promise<AxiosResponse<EntryHistory[]>> =>
	api.get(`/story/${storyId}/history`);

export const move = (storyId: string, milestoneId: string, rank?: number): Promise<AxiosResponse<Task>> =>
	api.post(`/story/${storyId}/move/${milestoneId}`, { rank });

export const clone = (storyId: string, parameters?: TaskClone): Promise<AxiosResponse<Task>> =>
	api.post(`/story/${storyId}/clone`, parameters);
