import { extendTheme } from '@chakra-ui/react';
import { AccordionStyles as Accordion } from './components/accordionStyles';
import { ButtonStyles as Button } from './components/buttonStyles';
import { TextareaStyles as Textarea } from './components/textareaStyles';
import { InputStyles as Input } from './components/inputStyles';
import { FormLabelStyles as FormLabel } from './components/formLabelStyles';
import { FormControlStyles as FormControl } from './components/formControlStyles';
import { FormErrorMessageStyles as FormErrorMessage } from './components/formErrorMessageStyles';
import { CheckboxStyles as Checkbox } from './components/checkboxStyles';
import { LinkStyles as Link } from './components/linkStyles';
import { TagStyles as Tag } from './components/tagStyles';
import { TabsStyles as Tabs } from './components/tabsStyles';
import { TableStyles as Table } from './components/tableStyles';
import { DrawerStyles as Drawer } from './components/drawerStyles';
import { TooltipStyles as Tooltip } from './components/tooltipStyles';
import { PopoverStyles as Popover } from './components/popoverStyles';
import { SwitchStyles as Switch } from './components/switchStyles';
import { CloseButtonStyles as CloseButton } from './components/closeButtonStyles';
import { ListStyles as List } from './components/listStyles';
import { MenuStyles as Menu } from './components/menuStyles';
import { mode } from '@chakra-ui/theme-tools';
import { StyleOptions } from './types';

// TODO: remove in future - keep for now to be able to verify components structure
// import { theme as chakraTheme } from '@chakra-ui/react';
// console.log(chakraTheme.components);
// console.log(chakraTheme.components.Menu);
// console.log(
// 	JSON.stringify(
// 		chakraTheme.components.Menu.baseStyle({
// 			theme: chakraTheme,
// 		}),
// 		null,
// 		2
// 	)
// );

export const theme = extendTheme({
	config: {
		initialColorMode:
			window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light',
	},
	fonts: {
		heading: 'Lato',
		body: 'Lato',
	},
	zIndices: {
		hide: -1,
		auto: 'auto',
		base: 0,
		docked: 10,
		dropdown: 1000,
		sticky: 1100,
		banner: 1200,
		overlay: 1300,
		modal: 1400,
		popover: 1500,
		skipLink: 1600,
		toast: 1700,
		tooltip: 1800,
	},
	fontSizes: {
		xs: '0.675rem',
		sm: '0.825rem',
		sx: '0.825rem',
		md: '1rem',
		lg: '1.125rem',
		xl: '1.25rem',
		'2xl': '1.5rem',
		'3xl': '1.75rem',
		'4xl': '2.25rem',
		'5xl': '2.575rem',
		'6xl': '3.75rem',
		'7xl': '4.5rem',
		'8xl': '6rem',
		'9xl': '8rem',
	},
	space: {
		px: '1px',
		1: '2px',
		2: '4px',
		3: '8px',
		4: '16px',
	},
	colors: {
		brand: {
			epek: {
				50: '#BB86FC',
				100: '#6B13FE',
			},
			primary: '#0092ED',
			primaryAction: '#BB86FC',
			primaryB: '#0092ED',
			blue: '#2374E1',
			green: '#219653',
			yellow: '#F2C94A',
			orange: '#F2994A',
			red: {
				100: '#F2994A',
				primary: '#EB5757',
			},
			status: {
				done: '#219653',
				in_progress: '#ffcd37',
				in_review: '#2374E1',
				todo: '#AAAAAA',
				blocked: '#EB5757',
			},
		},
		gray: {
			10: '#FFFFFF',
			25: '#f6f6f6',
			50: '#F2F2F2',
			75: 'rgb(226, 226, 226)',
			100: '#AAAAAA',
			200: '#808080',
			300: '#828282',
			400: '#4F4F4F',
			500: '#2E2E2E',
			600: '#222222',
			700: '#1E1E1E',
			800: '#121212',
		},
	},
	components: {
		Accordion,
		Button,
		CloseButton,
		Input,
		Checkbox,
		FormLabel,
		FormControl,
		FormErrorMessage,
		Textarea,
		Link,
		List,
		Tag,
		Tabs,
		Table,
		Drawer,
		Tooltip,
		Popover,
		Switch,
		Menu,
	},
	styles: {
		global: (props: StyleOptions) => ({
			'html, body': {
				bg: mode('white', 'gray.600')(props),
				bgSize: 'cover',
				bgPosition: 'center',
				fontSize: '14px',
				color: mode('gray.500', 'white')(props),
			},
			// Disable default browser auto-fill
			input: {
				':-webkit-autofill, :-webkit-autofill:hover, :-webkit-autofill:focus': {
					backgroundColor: 'transparent !important',
					boxShadow: 'none',
					webkitTextFillColor: mode('gray.600', 'white')(props),
				},
			},
			a: {
				_focus: {
					boxShadow: 'none',
					outline: 'none',
				},
			},
			'.selected-date': {
				fontWeight: 'bold',
				fontSize: '140%',
				color: 'orange',
			},
			'.selected-today': {
				fontWeight: 'bold',
				fontSize: '140%',
				color: 'red',
			},
			'.rdp': {
				margin: '0.2em',
				'.rdp-caption_label': {
					fontSize: 'md',
				},
				'.rdp-cell': {
					height: '30px',
					width: '30px',
				},
			},
		}),
	},
});
