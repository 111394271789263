import { mode } from '@chakra-ui/theme-tools';
import { StyleOptions } from '../types';

export const TableStyles = {
	baseStyle: {},
	sizes: {},
	variants: {
		base: (props: StyleOptions) => ({
			th: {
				color: 'gray.600',
				borderBottom: '1px',
				borderColor: 'gray.100',
				'&[data-is-numeric=true]': {
					textAlign: 'end',
				},
			},
			td: {
				borderBottom: '1px',
				borderColor: 'gray.100',
				'&[data-is-numeric=true]': {
					textAlign: 'end',
				},
			},
			thead: {
				tr: {
					borderBottom: '1px',
					borderTop: '1px',
					borderColor: 'gray.100',
					bg: mode('gray.50', 'gray.500')(props),
					th: {
						color: mode('gray.600', 'white')(props),
						fill: mode('gray.600', 'white')(props),
						borderBottomWidth: 0,
					},
					'&:last-of-type': {
						th: {
							borderBottomWidth: 0,
						},
					},
				},
			},
		}),
		simple: (props: StyleOptions) => ({
			tr: {
				'&:first-of-type': {
					borderTop: '1px solid',
					borderColor: mode('gray.300', 'gray.500')(props),
					'&[data-is-numeric=true]': {
						textAlign: 'end',
					},
				},
			},
			td: {
				borderBottom: '1px solid',
				borderColor: mode('gray.300', 'gray.500')(props),
				'&[data-is-numeric=true]': {
					textAlign: 'end',
				},
				'&:nth-of-type(odd)': {
					fontWeight: 'bold',
					bg: mode('gray.200', 'gray.600')(props),
				},
			},
		}),
	},
	defaultProps: {},
};
