const translations = {
	pageTitle: {
		'/': 'Epek',
		'/workspace': 'Todos los Proyectos',
		'/my-projects': 'Mis Proyectos',
		'/settings': 'Todas las Configuraciones',
	},
	common: {
		ApplicationName: 'EPEK',
		Add: 'Añadir',
		Cancel: 'Cancelar',
		Login: 'Iniciar Sesión',
		DueDate: 'Fecha de Vencimiento',
		URL: 'URL',
		Create: 'Crear',
		Clone: 'Clonar',
		Save: 'Guardar',
		Update: 'Actualizar',
		Logout: 'Cerrar Sesión',
		And: 'y',
		None: 'Ninguno',
		Yes: 'Sí',
		No: 'No',
	},
	translation: {
		Common: {
			ApplicationName: 'EPEK',
			Add: 'Añadir',
			Cancel: 'Cancelar',
			Login: 'Iniciar Sesión',
			Logout: 'Cerrar Sesión',
			Error: '¡Error!',
			Save: 'Guardar',
			Loading: 'Cargando...',
			Name: 'Nombre',
			Email: 'Correo Electrónico',
			Password: 'Contraseña',
			MobileMessage:
				'P.D. epek no está diseñado para móviles - sugerimos usarlo en tu portátil o computadora de escritorio.',
			AppSumo: 'AppSumo',
			Continue: 'Continuar',

			Filters: {
				ByStatus: 'Ordenar por estados',
				ByDueDate: 'Ordenar por fechas de vencimiento',
				ByRank: 'Ordenar por prioridades',
			},
		},
		TimeScale: {
			900: '3 Días',
			400: 'Semana',
			200: 'Dos semanas',
			100: 'Mes',
			50: 'Dos meses',
			17: 'Seis Meses',
			8: 'Año',
			Short: {
				900: '3d',
				400: '1s',
				200: '2s',
				100: '1m',
				50: '2m',
				17: '6m',
				8: '1a',
			},
		},
		Workspace: {
			SwitcherTitle: 'Espacios de Trabajo',
			Projects: 'Proyectos',
		},
		Settings: {
			Title: 'Todas las Configuraciones',
			UserSettings: 'Configuraciones del Perfil',
			Collaborators: 'Colaboradores del Espacio de Trabajo',
			WorkspaceSettings: 'Configuraciones del Espacio de Trabajo',
			Payment: 'Facturación',
			AppSumo: 'AppSumo',
			NameLabel: 'Tu nombre',
		},
		AppSumo: {
			Errors: {
				AlreadyActivated:
					'El código ya ha sido consumido. Por favor, contacta a nuestro equipo de soporte en <a href="{{contact}}">{{contact}}</a>',
				InvalidCode: 'El código actual es inválido',
			},
			Columns: {
				code: 'Código',
				startsAt: 'Comienza en',
				endsAt: 'Termina en',
				status: 'Estado',
			},
			ThankYou: 'Gracias por usar AppSumo',
			ThankYouDescription:
				'El plan AppSumo ha sido activado permitiéndote usar hasta 10 editores por un período de un año.',
			Activate: 'Activa tu oferta de AppSumo aquí',
			ActivatePlaceholder: 'Introduce el código que compraste en AppSumo',
			Increase: 'Aumenta el límite de editores con tu código de recarga de AppSumo',
			ActivateSubmit: 'Activar',
			Note: 'NOTA: Después de que tu plan expire, serás automáticamente degradado al plan gratuito. Puedes elegir agregar más editores a través del plan de equipo en cualquier momento si lo deseas.',
			IncreaseSubmit: 'Aumentar',
			History: 'Historial',
			DealDescription:
				'Nuestro acuerdo de socio con AppSumo ha finalizado. Tus códigos canjeados seguirán funcionando hasta su fecha de vencimiento.',
			CTA: '¿Quieres saber más o comprar de inmediato?',
			PricingCTA: '¡Oferta por Tiempo Limitado!',
			DealPageText: 'Consigue la oferta en AppSumo',
			FAQHint: '¿Tienes preguntas sobre la oferta de AppSumo? Consulta nuestras preguntas frecuentes sobre ella',
		},
		Support: {
			Title: '¿Cómo podemos ayudarte?',
			GeneralSupport: 'General',
			BillingSupport: 'Precios',
			Contact: 'Contacto',
		},
		Footer: {
			About: 'Acerca de',
			Legal: 'Legal',
		},
		Navigation: {
			Profile: 'Perfil',
			MyProjects: 'Mis Proyectos',
			Workspaces: 'Espacios de Trabajo',
		},
		Status: {
			TODO: 'Por Hacer',
			DONE: 'Hecho',
			BLOCKED: 'Bloqueado',
			IN_REVIEW: 'En Revisión',
			IN_PROGRESS: 'En Progreso',
			Label: 'Estado',
		},
		OnBoarding: {
			GetStarted: 'Empecemos.',
			SetUpYourTeam: 'Nombra tu espacio de trabajo',
			SetUpYourName: '¿Cómo te llamamos?',
			InviteMates: 'Invita a algunos compañeros de equipo',
			AddEmails: 'Agrega una dirección de correo electrónico',
			InviteMatesDescription:
				'También puedes invitar a tu equipo desde las configuraciones del espacio de trabajo más tarde.',
			Continue: 'Configurar y continuar',
			SendInvites: 'Enviar invitaciones',
			Skip: 'Omitir por ahora',
			AllSet: 'Todo listo.',
			BuildYourOwn: '¡Ve a hacer tus planes!',
			BuildYourOwnDescription:
				'Encontrarás un par de proyectos de ejemplo en tu espacio de trabajo. Échales un vistazo primero para ver cómo podrían verse tus planes y obtener una idea de lo que epek puede hacer por ti.',
			TeamName: '¿Cómo deberíamos nombrar tu espacio de trabajo?',
			YourName: '¿Cuál es tu nombre completo, por favor?',
		},
		Organization: {
			NameLabel: 'Organización',
			IdLabel: 'Id',
			CreatedLabel: 'Creado',
			Update: {
				WorkspaceSettings: 'Configuraciones del Espacio de Trabajo',
				NameLabel: 'Nombre del Espacio de Trabajo',
				LogoLabel: 'Imagen del Espacio de Trabajo',
				LogoChanged: 'Has cambiado la imagen del espacio de trabajo, recuerda presionar actualizar.',
			},
			Delete: {
				Title: 'ÁREA DE PELIGRO: Eliminación del Espacio de Trabajo',
				Request:
					'Si deseas que eliminemos este espacio de trabajo y todo lo que contiene, simplemente escríbenos un correo electrónico a support@epekworks.com y lo haremos por ti.',
				InfoToCopy:
					'Por favor, copia la siguiente información y añádela en tu correo electrónico de solicitud para que el personal de soporte pueda proceder con la eliminación.',
				Warning:
					'La eliminación del espacio de trabajo elimina toda la información relacionada y contenida en tu espacio de trabajo y no se puede revertir. Alguna información aún puede mantenerse por períodos legalmente obligatorios de acuerdo con las regulaciones de GDPR de la UE. Todavía podrás acceder a otros espacios de trabajo de los que seas miembro.',
			},
			Users: {
				Access: 'Todas las personas con acceso',
				Pending: 'Invitaciones pendientes',
				Invite: 'Invitar al espacio de trabajo por correo electrónico',
				SendInvites: 'Enviar invitaciones',
				SendInvitesError: 'Ya existe dentro de esta organización',
				Emails: 'Ingresa correos electrónicos aquí',
				NoUsers: 'Aún no tienes colaboradores.',
				NoUsersToShow: 'No hay usuarios para mostrar en esta sección.',
				FreeUsers: 'Conteo de Editores',
				FreeUsersInfo:
					'Este es el número total de editores utilizados de los editores disponibles en tu suscripción actual. El conteo de editores incluye al propietario del espacio de trabajo y a los administradores del espacio de trabajo.',
				NoPendingUsers: 'No tienes invitaciones pendientes.',
				Roles: {
					Admin: 'Administrador',
					Editor: 'Editor',
					Viewer: 'Visualizador',
					Member: 'Miembro',
					Owner: 'Propietario',
				},
				Actions: {
					MakeAdmin: {
						Description: 'Otorgar derechos de administrador',
						Confirm: 'Solo para estar seguros, ¿estás seguro?',
						Content: '¡Esta persona recibirá derechos de administrador para el espacio de trabajo!',
					},
					RemoveAdmin: {
						Description: 'Revocar derechos de administrador',
						Confirm: 'Solo para estar seguros, ¿estás seguro?',
						Content:
							'¡Esta persona ya NO tendrá derechos de administrador en el espacio de trabajo! Seguirá siendo miembro de tu espacio de trabajo.',
					},
					MakeEditor: {
						Description: 'Otorgar derechos de editor',
						Confirm: 'Solo para estar seguros, ¿estás seguro?',
						Content: '¡Esta persona recibirá derechos de editor para el proyecto!',
					},
					RemoveEditor: {
						Description: 'Revocar derechos de editor',
						Confirm: 'Solo para estar seguros, ¿estás seguro?',
						Content: '¡Esta persona ya NO tendrá derechos de editor en el proyecto!',
					},
					RemoveUser: {
						Description: 'Eliminar del espacio de trabajo',
						Confirm: 'Solo para estar seguros, ¿estás seguro?',
						Content:
							'Esta persona será eliminada de tu espacio de trabajo. ¡Ya NO tendrá ningún acceso al espacio de trabajo! Siempre puedes invitarla de nuevo si lo deseas.',
					},
					RemovePendingUser: {
						Description: 'Revocar invitación',
						Confirm: 'Solo para estar seguros, ¿estás seguro?',
						Content: 'La invitación será revocada. Siempre puedes invitarla de nuevo si lo deseas.',
					},
					InviteUsers: {
						Confirm: '¡Woohoo! ¡Esta fiesta apenas comienza!',
						Content:
							'{{users}} podrán participar en tu espacio de trabajo tan pronto como creen su cuenta. ¡Presiona el botón para enviar las invitaciones!',
					},
				},
				Errors: {
					MaxFreeEditors: {
						Title: 'Has alcanzado la cantidad máxima de editores en el plan GRATIS',
						Content: 'Actualiza al plan TEAM para tener más editores',
						OkText: 'Actualizar al plan Epek TEAM',
						CancelText: 'Más tarde',
					},
					MaxFreeProjects: {
						Title: 'Has alcanzado la cantidad máxima de proyectos en el plan GRATIS',
						Content: 'Actualiza al plan TEAM para tener más proyectos',
						OkText: 'Actualizar al plan Epek TEAM',
						CancelText: 'Más tarde',
					},
				},
			},
		},
		Project: {
			Timeline: {
				TimelineView: 'Vista de la línea de tiempo',
				ColumnView: 'Vista de columnas',
				ListView: 'Vista de lista',
				Backlog: 'Backlog',
				Today: 'Hoy',
				ShowTasks: 'Mostrar tareas',
				TasksVisible: 'Tareas visibles',
			},
			Item: {
				AddImagesFiles: 'Agregar imágenes y archivos',
				AddLink: 'Agregar enlace',
			},
			Milestone: {
				Header: 'Hito',
				Create: {
					Title: 'Agregar un hito',
					TitleLabel: 'Título del hito... (obligatorio)',
					DescriptionLabel: 'Descripción...',
					StartDateLabel: 'Fecha de inicio',
					EndDateLabel: 'Fecha de vencimiento',
					TagsLabel: 'Etiquetas...',
					CreateButtonLabel: 'Agregar un hito',
					TasksHint: 'Una vez que crees el hito, puedes agregar tareas aquí!',
				},
				Details: {
					Title: 'Detalles del hito',
				},
				Clone: {
					Title: 'Clonar hito',
					UnassignAssignees: 'Desasignar tareas y subtareas con asignados',
					ResetStatuses: 'Restablecer todos los estados a POR HACER',
					TasksToBacklog: 'Mover tareas clonadas al backlog',
					WithComments: 'Incluir comentarios',
				},
				Tasks: 'Tareas',
				Tags: 'Etiquetas',
				Task: 'Agregar nueva tarea',
				ListOfTasks: 'Lista de tareas',
				NoTasks: 'Este hito no tiene tareas para contar',
				NoTasksHint:
					'Las tareas son simplemente cosas que necesitas hacer para completar este hito o objetivo. Piénsalas como tareas que contarías cuando estés terminado, por ejemplo, "Publicar mi sitio web", "actualizar información de contacto", "enviar el ensayo para revisión"',
				DateRange: 'Fecha de vencimiento',
				StatusLabel: 'Estado',
				MoreInfoLabel: 'Enlaces',
				ImagesLabel: 'Imágenes',
				StartDate: '(opcional) Fecha de inicio',
				EndDate: 'Vencimiento',
				Duration: 'Duración',
			},
			Backlog: {
				Header: 'Backlog',
				Hint: 'Tu Backlog contiene todas las tareas (cosas por hacer) que aún no pertenecen a un hito específico. Piénsalo como un estacionamiento para ideas. Simplemente agrégalas aquí y luego puedes moverlas a hitos específicos, o enviarlas del hito de vuelta al backlog!',
				MilestonePlanningHint:
					'Puedes planificar tus hitos simplemente arrastrando una tarea del backlog a un hito, o arrastrando y soltando tareas entre hitos y el backlog. ¡Adelante, pruébalo!',
				Create: {
					Title: 'Agregar un hito',
					TitleLabel: 'Título del hito... (obligatorio)',
					DescriptionLabel: 'Descripción...',
					StartDateLabel: 'Fecha de inicio',
					EndDateLabel: 'Fecha de vencimiento',
					TagsLabel: 'Etiquetas...',
					CreateButtonLabel: 'Agregar un hito',
					TasksHint: 'Una vez que crees el hito, puedes agregar tareas aquí!',
				},
				Details: {
					Title: 'Di algo sobre tu backlog',
				},
				Tasks: 'Tareas',
				Task: 'Agregar nueva tarea',
				DateRange: 'Fecha de vencimiento',
				StatusLabel: 'Estado',
				MoreInfoLabel: 'Enlaces',
				StartDate: '(opcional) Fecha de inicio',
				EndDate: 'Vencimiento',
				Duration: 'Duración',
			},
			Project: {
				Create: {
					Title: 'Nombre del nuevo proyecto',
					Description: 'Descripción del proyecto',
				},
				Clone: {
					Title: 'Clonar proyecto',
					UnassignAssignees: 'Desasignar tareas y subtareas con asignados',
					ResetStatuses: 'Restablecer todos los estados a POR HACER',
					TasksToBacklog: 'Mover todas las tareas al backlog',
					WithComments: 'Incluir comentarios',
				},
				AddNewProject: 'Agregar nuevo proyecto',
				Header: 'Proyecto',
				NameLabel: 'Nombre del proyecto',
				MilestoneList: 'Todos los hitos',
				SelectOpenMilestone: 'Seleccionar uno de los hitos abiertos',
			},
			Zoom: 'Escala:',
			Task: {
				Header: 'Tarea',
				Create: {
					Title: 'Agregar una tarea',
					TitleLabel: 'Título... (obligatorio)',
					DescriptionLabel: 'Descripción...',
					StartDateLabel: 'Fecha de inicio',
					EndDateLabel: 'Vencimiento',
					TagsLabel: 'Etiquetas...',
				},
				Clone: {
					Title: 'Clonar tarea',
					UnassignAssignees: 'Desasignar tarea y subtareas con asignados',
					ResetStatuses: 'Restablecer estado(s) a POR HACER',
					TasksToBacklog: 'Mover tarea clonada al backlog',
					WithComments: 'Incluir comentarios',
				},
				ToBacklog: 'Volver al Backlog...',
				MoveTask: 'Mover a otro hito...',
				CloneTask: 'Clonar tarea',
				DeleteTask: 'Eliminar tarea',
				DateRange: 'Vencimiento',
				StatusLabel: 'Estado',
				MoreInfoLabel: 'Enlaces',
				AssigneeLabel: 'Asignado',
				Subtasks: 'Subtareas',
				Subtask: 'Agregar nueva subtarea',
				ListOfSubtasks: 'Lista de subtareas',
				NoSubtasks: 'Esta tarea aún no tiene subtareas',
				NoSubtasksHint:
					'Las subtareas son los detalles dentro de las tareas, por ejemplo, si la tarea era "enviar el ensayo para revisión", las subtareas podrían ser "Asegurarse de que el formato esté corregido", "Agregar pie de página con números de página", "Agregar referencias al final"',
				MoveHeader: 'Mover: ',
				CurrentMilestone: 'Desde:',
				NewMilestone: 'Hacia:',
			},
			Subtask: {
				Header: 'Subtarea',
				CloneSubtask: 'Clonar subtarea',
				DeleteSubtask: 'Eliminar subtarea',
				Create: {
					Title: 'Agregar nueva subtarea',
					TitleLabel: 'Título... (obligatorio)',
					DescriptionLabel: 'Descripción...',
					StartDateLabel: 'Fecha de inicio',
					EndDateLabel: 'Vencimiento',
					TagsLabel: 'Etiquetas...',
					UrlLabel: 'URL',
				},
				Clone: {
					Title: 'Clonar subtarea',
					UnassignAssignees: 'Desasignar asignados',
					ResetStatuses: 'Restablecer estado a POR HACER',
					WithComments: 'Incluir comentarios',
				},
				DateRange: 'Vencimiento',
				StatusLabel: 'Estado',
				MoreInfoLabel: 'Enlaces',
				AssigneeLabel: 'Asignado',
			},
		},
		Plan: {
			InviteUsers:
				'¡Invita a más personas desde la pantalla de configuración de colaboradores de tu espacio de trabajo!',
		},
		SignUp: {
			Title: 'Empieza con Epek',
			Login: 'Iniciar Sesión',
			Register: 'REGÍSTRATE',
			ResentVerification: 'Reenviar verificación',
			PasswordRules:
				'Nuestra política de contraseñas: mínimo 8 caracteres, incluyendo números. Recomendamos una mezcla de letras mayúsculas y minúsculas, números y caracteres especiales.',
		},
		ResendVerification: {
			Title: 'Verifica tu cuenta',
			Button: 'Enviar correo de verificación',
		},
		SignUpSuccess: {
			Title: 'Verificar dirección de correo electrónico',
			TextPartOne: 'Te hemos enviado un correo de verificación a la dirección: ',
			TextPartTwo:
				'. Por favor, revisa tu bandeja de entrada y verifica tu dirección de correo electrónico. Deberías poder iniciar sesión después.',
			SignupAgain: 'Regístrate de nuevo',
		},
		Login: {
			Title: 'Bienvenido de nuevo',
			TitleSimple: 'Bienvenido',
			SignUp: 'Regístrate',
			Login: 'Iniciar Sesión',
			LoginWithGoogle: 'Continuar con Google',
			LoginWithMagic: 'Continuar con Magic Link',
			TypePassword: '¿Prefieres escribir la contraseña?',
			PreferMagicLink: '¿Prefieres Magic Links?',
			MagicEmailSentTitle: '¿No es esto mágico?',
			MagicEmailSentTextOne: 'Tu enlace mágico está en camino a ',
			MagicEmailSentTextTwo: '¡Haz clic en el enlace para iniciar sesión y disfrutar de Epek!',
			ForgotPassword: '¿Olvidaste la contraseña?',
			CatchPhrase: '¡Nunca pierdas de vista tus objetivos!',
			NewToEpek: '¿Nuevo en Epek? Continúa con Google o Magic Link para registrarte e iniciar sesión de una vez.',
		},
		ForgotPassword: {
			Title: 'Solicitud de restablecimiento de contraseña',
			Text: 'Por favor, escribe la dirección de correo electrónico con la que te registraste, y te enviaremos el enlace para restablecer tu contraseña.',
			Request: 'Envíame el correo',
		},
		ForgotPasswordSuccess: {
			Title: '¡Enlace para restablecer la contraseña enviado!',
			Text: 'Tu enlace para restablecer la contraseña debería estar en tu bandeja de entrada justo ahora. Estamos aquí para ayudar si necesitas más apoyo.',
		},
		ResetPassword: {
			Title: 'Por favor, introduce una nueva contraseña',
			NewPassword: 'NuevaContraseña',
			ConfirmNewPassword: 'ConfirmarNuevaContraseña',
			Request: 'Actualizar Contraseña',
			PasswordShouldMatch: 'Las contraseñas no coinciden',
		},
		ResetPasswordSuccess: {
			Title: '¡Éxito!',
			Text: 'Tu contraseña ha sido restablecida con éxito. Ahora puedes iniciar sesión en tu espacio de trabajo con tu nueva contraseña.',
		},
		VerifyEmail: {
			ErrorTitle: 'Eso no funcionó...',
			ErrorText: 'No se pudo confirmar tu cuenta, esperamos que el siguiente mensaje de error explique por qué:',
			SuccessTitle: '¡Cuenta confirmada!',
			SuccessText:
				'Gracias por hacer clic en ese enlace. Tu dirección de correo electrónico ha sido verificada y todo está listo.',
		},
		Payments: {
			Tabs: {
				Subscription: 'Suscripción',
				CostCalculator: 'Calculadora de Costos',
				PaymentInfo: 'Información de Pago',
				Invoices: 'Facturas',
			},
			Subscribe: 'Mejorar',
			Unsubscribe: 'Degradar',
			UnsubscribePrompt: {
				Title: '¿Estás seguro de que quieres degradar del plan Epek TEAM?',
				Content:
					'Degradar del plan Epek TEAM convertirá a todos los editores adicionales en espectadores al final del período de facturación actual, dejando SOLO tres miembros del espacio de trabajo como editores. Siempre puedes cambiar los derechos de editor cuando lo desees.',
			},
			BillingPeriod: 'El período de facturación actual va desde <1>{{from}}</1> hasta <3>{{to}}</3>',
			Subscription: {
				Plan: 'Epek TEAM',
				PlanDescription:
					'Espectadores ilimitados, {{freeEditors}} editores + ${{pricePerEditor}} por cada editor adicional',
				CancelledInfo: 'La suscripción está cancelada. Y terminará al final del período actual.',
				BillingEndDate: 'La facturación terminó el {{endDate}}',
			},
			EstimatedBill: 'Factura mensual estimada ${{value}}',
			MissingPaymentMethod: 'Por favor, añade primero un método de pago',
			Errors: {
				PaymentMethodFetch: 'Error al recuperar información de pago',
				PaymentMethodChange: 'Error al cambiar el método de pago',
				PaymentMethodAdd: 'Error al añadir el método de pago',
				InvoicesFetch: 'Error al recuperar la lista de facturas',
				BillingDetailsChange: 'Error al actualizar los detalles de facturación',
			},
			PaymentMethod: 'Método de Pago',
			PaymentMethodInfo: '{{brand}} termina en {{last4}}',
			PaymentMethodEdit: 'Cambiar método de pago',
			PaymentMethodAdd: 'Añadir método de pago',
			PaymentMethodUpdated: 'Método de pago actualizado con éxito',
			BillingDetailsUpdate: 'Actualizar',
			PersonalUse: 'Uso personal',
			BillingName: 'Nombre de facturación',
			BillingAddress: 'Dirección de facturación',
			BillingTaxNumber: 'Número de IVA',
			BillingCountry: 'País',
			BillingDetailsChanged: 'Detalles de facturación actualizados con éxito',
			InvoiceTable: {
				createdAt: 'Fecha',
				status: 'Estado',
				total: 'Total',
				pdfUrl: 'Descargar PDF',
			},
		},
		GenericPageError: {
			Title: 'Algo salió mal',
			Text: 'Por favor, intenta recargar la página o volver a la página de inicio',
			Button: 'Recargar',
		},
		Messages: {
			URLCopy: {
				Done: 'el enlace ha sido copiado al portapapeles',
			},
			History: {
				create: 'creado',
				update: 'actualizado',
				delete: 'eliminado',
				clone: 'clonado',
				move: 'movido',
				assign: 'asignado cambiado',
				status: 'estado cambiado',
				public: 'hecho público',
				not_public: 'hecho privado',
			},
			Filters: {
				ByStatusError: 'Desactiva Ordenar Por Fechas de Vencimiento para cambiar las prioridades de las tareas',
			},
			Subtask: {
				Created: 'Subtarea ha sido creada',
				CreatedError: 'Error al crear la subtarea',
				Cloned: 'Subtarea ha sido clonada',
				ClonedError: 'Error al clonar la subtarea',
				Updated: 'Subtarea ha sido actualizada',
				UpdatedError: 'Error al actualizar la subtarea',
				Deleted: 'Subtarea ha sido eliminada',
				DeletedError: 'Error al eliminar la subtarea',
				NoTitleError: 'Título de la subtarea es requerido',
				NotFound: 'Subtarea no encontrada',
			},
			Task: {
				Moved: 'Tarea ha sido movida',
				MovedError: 'Error al mover la tarea',
				Created: 'Tarea ha sido creada',
				CreatedError: 'Error al crear la tarea',
				Cloned: 'Tarea ha sido clonada',
				ClonedError: 'Error al clonar la tarea',
				Updated: 'Tarea ha sido actualizada',
				UpdatedError: 'Error al actualizar la tarea',
				Deleted: 'Tarea ha sido eliminada',
				DeletedError: 'Error al eliminar la tarea',
				NoTitleError: 'Título de la tarea es requerido',
				SameMilestone: 'La tarea ya está en el hito objetivo',
				NotFound: 'Tarea no encontrada',
			},
			Milestone: {
				Created: 'Hito ha sido creado',
				CreatedError: 'Error al crear el hito',
				Cloned: 'Hito ha sido clonado',
				ClonedError: 'Error al clonar el hito',
				Updated: 'Hito ha sido actualizado',
				UpdatedError: 'Error al actualizar el hito',
				Deleted: 'Hito ha sido eliminado',
				DeletedError: 'Error al eliminar el hito',
				NoTitleError: 'Título del hito es requerido',
				NotFound: 'Hito no encontrado',
			},
			Folder: {
				Created: 'Nueva Carpeta ha sido añadida al final de la lista',
			},
			Project: {
				Created: 'Proyecto ha sido creado',
				CreatedError: 'Error al crear el proyecto',
				Cloned: 'Proyecto ha sido clonado',
				ClonedError: 'Error al clonar el proyecto',
				Updated: 'Proyecto ha sido actualizado',
				UpdatedError: 'Error al actualizar el proyecto',
				Deleted: 'Proyecto ha sido eliminado',
				DeletedError: 'Error al eliminar el proyecto',
				NoTitleError: 'Título del proyecto es requerido',
				NotFoundError: 'No se pudo encontrar este proyecto',
				ViewerMessage:
					'Solo tienes derechos de VISOR en este proyecto. Por favor, habla con el propietario del espacio de trabajo o los administradores para que te otorguen derechos de edición, si es necesario.',
				MashupMessage:
					'Nota: La vista de mezcla/mashup es solo de visualización, por lo que no podrás editar los hitos o ítems dentro.',
			},
			UserDetails: {
				Updated: 'Tus detalles han sido actualizados',
				UpdatedError: 'Error al actualizar tus detalles',
				NoTitleError: 'Tu nombre es requerido',
			},
			UserWorkspace: {
				Invite: 'Tus invitaciones serán enviadas de inmediato',
				InviteWithoutEmails: 'Por favor, añade las direcciones de correo electrónico de los invitados',
				Leave: 'Has abandonado ese espacio de trabajo',
				LeaveError: 'Error al abandonar el espacio de trabajo',
				LeaveOwnOrgError: 'No puedes abandonar tu propio espacio de trabajo',
				Updated: 'Tu espacio de trabajo ha sido actualizado',
				UpdatedError: 'Error al actualizar el nombre o logo del espacio de trabajo',
				NoTitleError: 'Nombre del espacio de trabajo es requerido',
			},
		},
	},
};

export default translations;
