export enum Route {
	Settings = '/settings',
	Payments = '/settings/payments',
	AppSumo = '/settings/appSumo',
	Collaborators = '/settings/collaborators',
	WorkspaceSettings = '/settings/workspace',
	Workspace = '/workspace',
	Onboarding = '/onboarding',
	NotFound = '/404',
	Support = '/support',
	BillingSupport = '/support/billing',
	GeneralSupport = '/support/general',
	Contact = '/support/contact',
	Login = '/login',
}
