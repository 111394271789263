import { mode } from '@chakra-ui/theme-tools';
import { StyleOptions } from '../types';

export const TagStyles = {
	baseStyle: {},
	sizes: {},
	variants: {
		primary: {
			container: {
				bg: 'brand.primary',
				color: 'white',
				borderRadius: '10px',
			},
		},
		primaryOutline: {
			container: {
				bg: 'transparent',
				color: 'brand.primary',
				border: '1px solid',
				borderColor: 'brand.primary',
				borderRadius: '10px',
			},
		},
		danger: {
			container: {
				bg: 'brand.danger.primary',
				color: 'white',
			},
		},
		dangerOutline: {
			container: {
				bg: 'transparent',
				border: '1px solid',
				borderColor: 'brand.danger.primary',
				color: 'brand.danger.primary',
			},
		},
		success: (props: StyleOptions) => ({
			container: {
				bg: 'brand.success.primary',
				color: mode('white', 'gray.700')(props),
			},
		}),
		successOutline: {
			container: {
				bg: 'transparent',
				border: '1px solid',
				borderColor: 'brand.success.primary',
				color: 'brand.success.primary',
			},
		},
		grayOutline: (props: StyleOptions) => ({
			bg: 'transparent',
			border: '1px solid',
			borderColor: mode('gray.400', 'gray.300')(props),
			color: mode('gray.400', 'gray.300')(props),
		}),
		info: (props: StyleOptions) => ({
			container: {
				fontWeight: 'bold',
				// padding: '5px',
				width: '100%',
				display: 'inline-flex',
				alignItems: 'center',
				justifyContent: 'center',
				bg: 'brand.info.primary',
				color: mode('white', 'gray.700')(props),
			},
		}),
	},
};
