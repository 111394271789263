import { mode } from '@chakra-ui/theme-tools';
import { StyleOptions } from '../types';

export const TabsStyles = {
	baseStyle: {
		tablist: {
			borderBottom: '3px solid',
		},
		tab: {
			_focus: {
				boxShadow: 'none',
			},
		},
	},
	variants: {
		line: (props: StyleOptions) => ({
			tablist: {
				borderBottom: '2px solid',
				borderColor: mode('gray.50', 'gray.500')(props),
			},
			tab: {
				borderBottom: '2px solid',
				marginBottom: '-2px',
				color: 'gray.300',
				borderColor: mode('gray.50', 'gray.500')(props),
				justifyContent: 'flex-start',
				_disabled: {
					cursor: 'not-allowed',
					opacity: 0.4,
				},
				_selected: {
					color: mode('black', 'white')(props),
					fontWeight: 600,
					borderColor: 'brand.primary',
				},
			},
		}),
		vertical: (props: StyleOptions) => ({
			tablist: {
				borderBottom: '0',
			},
			tab: {
				borderBottom: '2px solid',
				my: '2px',
				color: 'gray.300',
				borderColor: 'transparent',
				justifyContent: 'flex-start',
				_disabled: {
					cursor: 'not-allowed',
					opacity: 0.4,
				},
				_selected: {
					color: 'brand.primary',
					fontWeight: 600,
					// borderColor: 'brand.primary',
				},
			},
		}),
	},
};
