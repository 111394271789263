import { mode } from '@chakra-ui/theme-tools';
import { StyleOptions } from '../types';

export const LinkStyles = {
	baseStyle: {
		button: {
			margin: '0 5px',
			borderRadius: 4,
			textDecoration: 'none',
			_hover: {
				textDecoration: 'none',
			},
		},
	},
	sizes: {},
	variants: {
		primary: {
			color: 'brand.primary',
			borderRadius: '4px',
		},
		secondary: (props: StyleOptions) => ({
			textAlign: 'center',
			color: mode('black', 'white')(props),
			padding: '5px 15px',
			border: '1px solid',
			borderColor: mode('black', 'white')(props),
			borderRadius: '4px',
			_hover: {
				bg: mode('gray.50', 'gray.800')(props),
			},
		}),
		primaryFilled: (props: StyleOptions) => ({
			textAlign: 'center',
			backgroundColor: 'brand.primary',
			color: mode('black', 'white')(props),
			padding: '5px 15px',
		}),
		regularFilled: (props: StyleOptions) => ({
			backgroundColor: 'black',
			color: 'white',
			padding: '5px 15px',
		}),
		regular: {
			color: 'inherit',
			_hover: { textDecoration: 'none' },
		},
		regularUnderline: (props: StyleOptions) => ({
			color: mode('black', 'white')(props),
			textDecoration: 'underline',
		}),
		underlinedNav: (props: StyleOptions) => ({
			color: mode('black', 'white')(props),
			padding: '5px',
			lineHeight: '1.1rem',
			borderColor: mode('black', 'white')(props),
			borderBottom: '1px solid',
			_hover: { textDecoration: 'none', borderBottom: '2px solid', fontWeight: 'bolder' },
		}),
	},
	defaultProps: {
		variant: 'primary',
	},
};
