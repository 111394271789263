import * as user from './user';
import * as organization from './organization';
import * as project from './project';
import * as payments from './payment';
import * as milestone from './milestone';
import * as task from './task';
import * as subtask from './subtask';
import * as comment from './comment';

const apis = {
	user,
	organization,
	project,
	payments,
	milestone,
	task,
	subtask,
	comment,
};

export default apis;
