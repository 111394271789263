import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex } from '@chakra-ui/react';

export const ErrorMessage = () => {
	const { t } = useTranslation();
	const reload = () => window.location.reload();
	return (
		<Flex width="100%" textAlign="center" flexDirection="column" justifyContent="center" alignItems="center">
			<h1>{t('GenericPageError.Title')}</h1>
			<div>{t('GenericPageError.Text')}</div>
			<button onClick={reload}>{t('GenericPageError.Button')}</button>
		</Flex>
	);
};
