import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en';
import de from './de';
import es from './es';

export default i18n.use(initReactI18next).use(LanguageDetector).init({
	resources: { en, de, es },
	fallbackLng: 'en',
});
