import { ActionTypes } from '../../types/ActionType';
import * as actions from './actions';
import { AuthUser } from '../../types/AuthUser';
import { UserDetails } from '../../types/UserDetails';
import { Organization } from '../../types/Organization';

// Actions
export const SET_USER = 'SET_USER';
export const SET_ACTIVE_ORGANIZATION = 'SET_ACTIVE_ORGANIZATION';
export const SET_ORGANIZATIONS = 'SET_ORGANIZATIONS';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const SET_STATUS_FILTER = 'SET_STATUS_FILTER';
export const SET_DUE_DATE_FILTER = 'SET_DUE_DATE_FILTER';
export const SET_USER_LOADED = 'SET_USER_LOADED';
export const DELETE_USER = 'DELETE_USER';

// Context specific types
export type IUserAction = ActionTypes<typeof actions>;
export interface IUserState {
	user?: AuthUser;
	userLoaded?: boolean;
	details?: UserDetails;
	organization?: Organization;
	organizations: Organization[];
	isOnBoarded: boolean;
	filters: { byStatus: boolean; byDueDate: boolean };
}
