import { AxiosResponse } from 'axios';
import api from './base';
import { Organization } from '../types/Organization';
import { User } from '../types/User';
import { PendingUser } from '../types/PendingUser';
import { Folder, FolderStructure, Project } from '../types/Project';

export const getActiveOrganization = (): Promise<AxiosResponse<{ active: Organization }>> =>
	api.get('/organization/active');

export const setActiveOrganization = (organizationId: string): Promise<AxiosResponse<Organization>> =>
	api.post(`/organization/active/${organizationId}`);

export const acceptPendingInvites = (): Promise<AxiosResponse<void>> => api.get('/user/accept/invites');

export const create = (name: string): Promise<AxiosResponse<Organization>> => api.put('/organization', { name });

export const remove = (organizationId: string): Promise<AxiosResponse<void>> =>
	api.delete(`/organization/${organizationId}`);

export const inviteUsersToOrganization = (organizationId: string, emails: string[]): Promise<AxiosResponse<void>> =>
	api.post(`/organization/${organizationId}/addMembers`, emails);

export const leave = (organizationId: string): Promise<AxiosResponse<void>> =>
	api.delete(`/organization/${organizationId}/leave`);

export const get = (organizationId: string): Promise<AxiosResponse<Organization>> =>
	api.get(`/organization/${organizationId}`);

export const getPendingUsers = (organizationId: string): Promise<AxiosResponse<PendingUser[]>> =>
	api.get(`/organization/${organizationId}/invited`);

export const getAll = (): Promise<AxiosResponse<Organization[]>> => api.get('/organization');

export const getUsers = (organizationId: string): Promise<AxiosResponse<User[]>> =>
	api.get(`/organization/${organizationId}/users`);

export const removeUser = (organizationId: string, memberId: string): Promise<AxiosResponse<void>> =>
	api.delete(`/organization/${organizationId}/removeMember/${memberId}`);

export const removePendingUser = (organizationId: string, email: string): Promise<AxiosResponse<void>> =>
	api.post(`/organization/${organizationId}/invite/revoke`, { email });

export const setAdmin = (organizationId: string, memberId: string): Promise<AxiosResponse<void>> =>
	api.put(`/organization/${organizationId}/admin/${memberId}`);

export const unsetAdmin = (organizationId: string, memberId: string): Promise<AxiosResponse<void>> =>
	api.delete(`/organization/${organizationId}/admin/${memberId}`);

export const update = (organizationId: string, name: string, logo: string): Promise<AxiosResponse<Organization>> =>
	api.post(`/organization/${organizationId}`, { name, logo });

export const getProjects = (organizationId: string): Promise<AxiosResponse<Project[]>> =>
	api.get(`/organization/${organizationId}/projects`);

export const getFolderedProjects = (
	organizationId: string,
	folderId?: string
): Promise<AxiosResponse<FolderStructure>> =>
	api.get(`/organization/${organizationId}/folder${folderId ? `/${folderId}` : ''}`);

export const getFolderStructure = (organizationId: string): Promise<AxiosResponse<FolderStructure>> =>
	api.get(`/organization/${organizationId}/folderStructure`);

export const createFolder = (
	organizationId: string,
	folder: Pick<Folder, 'name' | 'parentId'>
): Promise<AxiosResponse<FolderStructure>> => api.put(`/organization/${organizationId}/folder`, folder);

export const updateFolder = (
	folder: Partial<Pick<Folder, 'id' | 'name' | 'parentId'>>
): Promise<AxiosResponse<FolderStructure>> =>
	api.post(`/organization/folder/${folder.id}`, { name: folder.name, parentId: folder.parentId });

export const removeFolder = (folderId: string): Promise<AxiosResponse<FolderStructure>> =>
	api.delete(`/organization/folder/${folderId}`);

export const moveProjectToFolder = (projectId: string, folderId?: string): Promise<AxiosResponse<FolderStructure>> =>
	api.put(`/organization/folder/move`, { projectId, folderId });
