import { AxiosResponse } from 'axios';
import api from './base';
import { Subtask, SubtaskClone } from '../types/Subtask';
import { EntryHistory } from '../types/EntryHistory';

export const create = (
	storyId: string,
	task: Omit<Subtask, 'id'> & { statusUpdate?: boolean }
): Promise<AxiosResponse<Subtask>> => api.put('/task', { storyId, ...task });

export const remove = (taskId: string): Promise<AxiosResponse<Subtask>> => api.delete(`/task/${taskId}`);

export const update = (
	taskId: string,
	task: Omit<Subtask, 'id'> & { statusUpdate?: boolean }
): Promise<AxiosResponse<Subtask>> => api.post(`/task/${taskId}`, { ...task });

export const get = (taskId: string): Promise<AxiosResponse<Subtask>> => api.get(`/task/${taskId}`);

export const history = (taskId: string): Promise<AxiosResponse<EntryHistory[]>> => api.get(`/task/${taskId}/history`);

export const clone = (taskId: string, parameters?: SubtaskClone): Promise<AxiosResponse<Subtask>> =>
	api.post(`/task/${taskId}/clone`, parameters);
