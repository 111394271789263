import React from 'react';
import { render } from 'react-dom';
import { App } from './App';
// import * as serviceWorker from './serviceWorker';
import { init } from '@sentry/browser';

if (process.env.NODE_ENV !== 'development') {
	init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		environment: process.env.REACT_APP_ENV,
	});
}

render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
