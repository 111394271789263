import { Comment, CommentEntryType } from '../types/Comment';
import { AxiosResponse } from 'axios';
import api from './base';

export const createSection = (entryType: CommentEntryType, entryId: string): Promise<AxiosResponse<{ id: string }>> =>
	api.put(`/comments/${entryType}/${entryId}`);

export const create = (
	sectionId: string,
	comment: Omit<Comment, 'id' | 'sectionId' | 'userId'>
): Promise<AxiosResponse<Comment>> => api.put(`/comments/${sectionId}`, { ...comment });

export const remove = (commentId: string): Promise<AxiosResponse<Comment>> => api.delete(`/comments/${commentId}`);

export const update = (
	commentId: string,
	comment: Omit<Comment, 'id' | 'sectionId' | 'userId'>
): Promise<AxiosResponse<Comment>> => api.post(`/comments/${commentId}`, { ...comment });

export const get = (sectionId: string): Promise<AxiosResponse<Comment[]>> => api.get(`/comments/${sectionId}`);
