import { mode } from '@chakra-ui/theme-tools';
import { StyleOptions } from '../types';

export const DrawerStyles = {
	baseStyle: (props: StyleOptions) => ({
		overlay: {
			bg: 'blackAlpha.600',
			zIndex: 'overlay',
		},
		dialogContainer: {
			display: 'flex',
			zIndex: 'modal',
			justifyContent: 'center',
		},
		dialog: {
			zIndex: 'modal',
			maxH: '100vh',
			bg: mode('white', 'gray.700')(props),
			color: 'inherit',
			boxShadow: 'lg',
			pl: '20px',
			pr: '20px',
		},
		header: {
			px: 6,
			py: 4,
			pt: 10,
			fontSize: '5xl',
			fontWeight: 'semibold',
			borderColor: 'gray.300',
		},
		closeButton: {
			position: 'absolute',
			top: 2,
			insetEnd: 3,
		},
		body: {
			px: 6,
			py: 2,
			flex: 1,
			overflow: 'auto',
		},
		footer: {
			px: 6,
			py: 4,
		},
	}),
	sizes: {},
	variants: {},
	defaultProps: {},
};
