export const pricePerEditor = 6;
//export const enterprisePricePerEditor = 39.99;
export const freeEditors = 3;
export const freeProjects = 5;

export const apiUri = process.env.REACT_APP_GRAPHQL_SERVER?.replace('/graphql', '');

export const tokenKey = 'USER_TOKEN_KEY';

export const magicLinkKey = 'MAGIC_LINK_USER_KEY';

export const appSumoCodeKey = 'APP_SUMO_CODE';
