import { AxiosResponse } from 'axios';
import api from './base';
import { Invoice } from '../types/Invoice';
import { PaymentMethod } from '../types/PaymentMethod';
import { SubscriptionsSummary } from '../types/SubscriptionsSummary';

export const invoices = (): Promise<AxiosResponse<Invoice[]>> => api.get('/payment/invoices');

export const paymentMethods = (): Promise<AxiosResponse<PaymentMethod[]>> => api.get('/payment/methods');

export const updatePaymentMethod = (paymentId: string): Promise<AxiosResponse<PaymentMethod[]>> =>
	api.post('/payment/methods', { paymentId });

export const subscriptions = (): Promise<AxiosResponse<SubscriptionsSummary>> => api.get(`/payment/subscriptions`);

export const subscribe = (): Promise<AxiosResponse<SubscriptionsSummary>> => api.get('/payment/subscribe');

export const unsubscribe = (): Promise<AxiosResponse<SubscriptionsSummary>> => api.get('/payment/unsubscribe');
