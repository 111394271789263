import { AxiosResponse } from 'axios';
import api from './base';
import { Project, ProjectClone, ProjectFilters } from '../types/Project';
import { User } from '../types/User';
import { EntryHistory } from '../types/EntryHistory';

export const create = (name: string, organizationId: string, description?: string): Promise<AxiosResponse<Project>> =>
	api.put('/project', { name, description, organizationId });

export const remove = (projectId: string): Promise<AxiosResponse<Project>> => api.delete(`/project/${projectId}`);

export const get = (projectId: string, filters?: ProjectFilters): Promise<AxiosResponse<Project>> =>
	api.get(`/project/${projectId}`, {
		params: filters,
	});

export const history = (projectId: string): Promise<AxiosResponse<EntryHistory[]>> =>
	api.get(`/project/${projectId}/history`);

export const clone = (projectId: string, parameters?: ProjectClone): Promise<AxiosResponse<Project>> =>
	api.post(`/project/${projectId}/clone`, {
		withMilestones: true,
		withStories: true,
		...parameters,
	});

export const cloneSampleProject = (projectId: string, organizationId?: string): Promise<AxiosResponse<Project>> =>
	api.post(`/project/${projectId}/cloneSample`, {
		withMilestones: true,
		withStories: true,
		unassignAssignees: true,
		resetStatuses: false,
		storiesToBacklog: false,
		organizationId,
	});

export const changeVisibility = (projectId: string, isPublic: boolean): Promise<AxiosResponse<Project>> =>
	api.post(`/project/${projectId}/visibility`, { public: isPublic });

export const exportProject = (projectId: string): Promise<AxiosResponse<Blob>> =>
	api({
		url: `/project/${projectId}/export`,
		method: 'GET',
		responseType: 'blob', // important
	});

export const getUsers = (projectId: string): Promise<AxiosResponse<User[]>> => api.get(`/project/${projectId}/users`);

export const setEditor = (projectId: string, memberId: string): Promise<AxiosResponse<void>> =>
	api.put(`/project/${projectId}/editor/${memberId}`);

export const unsetEditor = (projectId: string, memberId: string): Promise<AxiosResponse<void>> =>
	api.delete(`/project/${projectId}/editor/${memberId}`);

export const update = (
	projectId: string,
	organizationId: string,
	name: string,
	description?: string,
	status?: boolean,
	limited?: boolean
): Promise<AxiosResponse<Project>> =>
	api.post(`/project/${projectId}`, { name, description, statusUpdate: status, ownerLimited: limited });
