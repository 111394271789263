const translations = {
	pageTitle: {
		'/': 'Epek',
		'/workspace': 'All Projects',
		'/my-projects': 'My Projects',
		'/settings': 'All Settings',
	},
	common: {
		ApplicationName: 'EPEK',
		Add: 'Add',
		Cancel: 'Cancel',
		Login: 'Login',
		DueDate: 'Due Date',
		URL: 'URL',
		Create: 'Create',
		Clone: 'Clone',
		Save: 'Save',
		Update: 'Update',
		Logout: 'Logout',
		And: 'and',
		None: 'None',
		Yes: 'Yes',
		No: 'No',
	},
	translation: {
		Common: {
			ApplicationName: 'EPEK',
			Add: 'Add',
			Cancel: 'Cancel',
			Login: 'Login',
			Logout: 'Logout',
			Error: 'Error!',
			Save: 'Save',
			Loading: 'Loading...',
			Name: 'Name',
			Email: 'Email',
			Password: 'Password',
			MobileMessage:
				'P.S. epek is not built for mobile - we suggest using it on your laptop or desktop computer.',
			AppSumo: 'AppSumo',
			Continue: 'Continue',

			Filters: {
				ByStatus: 'Sort by statuses',
				ByDueDate: 'Sort by due dates',
				ByRank: 'Sort by priorities',
			},
		},
		TimeScale: {
			900: '3 Days',
			400: 'Week',
			200: 'Two weeks',
			100: 'Month',
			50: 'Two months',
			17: 'Six Months',
			8: 'Year',
			Short: {
				900: '3d',
				400: '1w',
				200: '2w',
				100: '1m',
				50: '2m',
				17: '6m',
				8: '1y',
			},
		},
		Workspace: {
			SwitcherTitle: 'Workspace(s)',
			Projects: 'Projects',
		},
		Settings: {
			Title: 'All Settings',
			UserSettings: 'Profile settings',
			Collaborators: 'Workspace collaborators',
			WorkspaceSettings: 'Workspace settings',
			Payment: 'Billing',
			AppSumo: 'AppSumo',
			NameLabel: 'Your name',
		},
		AppSumo: {
			Errors: {
				AlreadyActivated:
					'Code has already been consumed. Please contact our support team at <a href="{{contact}}">{{contact}}</a>',
				InvalidCode: 'Current code is invalid',
			},
			Columns: {
				code: 'Code',
				startsAt: 'Starts at',
				endsAt: 'Ends at',
				status: 'Status',
			},
			ThankYou: 'Thank you for using AppSumo',
			ThankYouDescription:
				'AppSumo plan has been activated allowing you to use up to 10 editors for a year period.',
			Activate: 'Activate your AppSumo deal here',
			ActivatePlaceholder: 'Enter the code you bought from AppSumo',
			Increase: 'Increase editors limit with your top-up AppSumo code',
			ActivateSubmit: 'Activate',
			Note: "NOTE:  After your plan expires, you'll automatically be downgraded to the free plan. You can choose to add more editors via the Team plan at any time if you like.",
			IncreaseSubmit: 'Increase',
			History: 'History',
			DealDescription:
				'Our partner deal with AppSumo has ended. Your redeemed codes will continue to work until their expiry.',
			CTA: 'Want to know more or buy right away?',
			PricingCTA: 'Limited Time Deal!',
			DealPageText: 'Get the deal on AppSumo',
			FAQHint: 'Got questions about the appSumo deal? Check out our FAQ about it',
		},
		Support: {
			Title: 'How can we help?',
			GeneralSupport: 'General',
			BillingSupport: 'Pricing',
			Contact: 'Contact',
		},
		Footer: {
			About: 'About',
			Legal: 'Legal',
		},
		Navigation: {
			Profile: 'Profile',
			MyProjects: 'My Projects',
			Workspaces: 'Workspaces',
		},
		Status: {
			TODO: 'To Do',
			DONE: 'Done',
			BLOCKED: 'Blocked',
			IN_REVIEW: 'In review',
			IN_PROGRESS: 'In progress',
			Label: 'Status',
		},
		OnBoarding: {
			GetStarted: "Let's get you started.",
			SetUpYourTeam: 'Name your workspace',
			SetUpYourName: 'How do we call you?',
			InviteMates: 'Invite some teammates',
			AddEmails: 'Add an Email address',
			InviteMatesDescription: 'You can also invite your team from the workspace settings later.',
			Continue: 'Set up and continue',
			SendInvites: 'Send invites',
			Skip: 'Skip for now',
			AllSet: "You're all set.",
			BuildYourOwn: 'Go make your plans!',
			BuildYourOwnDescription:
				'You will find a couple of example projects in your workspace. Take a look at them first to see how your plans could look like, and get an idea of what epek can do for you!',
			TeamName: 'How should we name your workspace?',
			YourName: 'What is your full name please?',
		},
		Organization: {
			NameLabel: 'Organization',
			IdLabel: 'Id',
			CreatedLabel: 'Created',
			Update: {
				WorkspaceSettings: 'Workspace Settings',
				NameLabel: 'Workspace Name',
				LogoLabel: 'Workspace Image',
				LogoChanged: 'You have changed workspace image, remember to press update.',
			},
			Delete: {
				Title: 'DANGER AREA: Workspace Deletion',
				Request:
					"If you would like to us to delete this workspace and everything within it, just write us an email at support@epekworks.com and we'll do it for you.",
				InfoToCopy:
					'Please copy the following info and add it within your request email, so the support staff can proceed with deletion.',
				Warning:
					'Workspace deletion deletes all information related to and within your workspace and can not be reverted. Some information may still be kept for legally binding periods in line with EU GDPR regulations. You will still be able to access other workspaces that your are a member of.',
			},
			Users: {
				Access: 'All people with access',
				Pending: 'Pending invites',
				Invite: 'Invite to workspace by email',
				SendInvites: 'Send invites',
				SendInvitesError: 'Already exists within this organization',
				Emails: 'Enter emails here',
				NoUsers: "You don't have any collaborators yet.",
				NoUsersToShow: 'There are no users to show on this section.',
				FreeUsers: 'Editor Count',
				FreeUsersInfo:
					'This is total number of used editors out of the available editors on your current subscription. Editor count includes the workspace owner and the workspace administrators.',
				NoPendingUsers: "You don't have any pending invites.",
				Roles: {
					Admin: 'Administrator',
					Editor: 'Editor',
					Viewer: 'Viewer',
					Member: 'Member',
					Owner: 'Owner',
				},
				Actions: {
					MakeAdmin: {
						Description: 'Grant admin rights',
						Confirm: 'Just to be on the safe side, are you sure?',
						Content: 'This person will be granted admin rights to the workspace!',
					},
					RemoveAdmin: {
						Description: 'Revoke admin rights',
						Confirm: 'Just to be on the safe side, are you sure?',
						Content:
							'This person will NO longer have admin rights to the workspace! They will still be a member of your workspace.',
					},
					MakeEditor: {
						Description: 'Grant editor rights',
						Confirm: 'Just to be on the safe side, are you sure?',
						Content: 'This person will be granted editor rights to the project!',
					},
					RemoveEditor: {
						Description: 'Revoke editor rights',
						Confirm: 'Just to be on the safe side, are you sure?',
						Content: 'This person will NO longer have editor rights to the project!',
					},
					RemoveUser: {
						Description: 'Remove from workspace',
						Confirm: 'Just to be on the safe side, are you sure?',
						Content:
							'This person will be removed from your workspace. They will NO longer have any access to the workspace! You can always invite them again if you like.',
					},
					RemovePendingUser: {
						Description: 'Revoke invitation',
						Confirm: 'Just to be on the safe side, are you sure?',
						Content: 'The invitation will be revoked. You can always invite them again if you like.',
					},
					InviteUsers: {
						Confirm: 'Woohoo! This party is just getting started!',
						Content:
							'{{users}} will be able to participate in your workspace as soon as they create their account. Press the button to send the invites!',
					},
				},
				Errors: {
					MaxFreeEditors: {
						Title: 'You have reached the maximum amount of editors on FREE plan',
						Content: 'Upgrade to TEAM plan to have more editors',
						OkText: 'Upgrade to Epek TEAM plan',
						CancelText: 'Later',
					},
					MaxFreeProjects: {
						Title: 'You have reached the maximum amount of projects on FREE plan',
						Content: 'Upgrade to TEAM plan to have more projects',
						OkText: 'Upgrade to Epek TEAM plan',
						CancelText: 'Later',
					},
				},
			},
		},
		Landing: {
			GoToWorkspace: 'Your Workspace',
			ContactSales: 'Contact Sales',
			GetStarted: 'GET STARTED FOR FREE',
			TryEpek: 'TRY FOR FREE',
		},
		UserDetails: {
			Update: {
				UserDetailsSettings: 'Your Details',
				NameLabel: 'Display Name',
				EmailLabel: 'Email',
				LogoLabel: 'Avatar',
				EmailNotificationsLabel: 'Email notifications',
				LogoChanged: 'You have changed the avatar, remember to press update.',
			},
			Delete: {
				Title: 'DANGER AREA: Account Deletion',
				Request:
					"If you would like to us to delete your account, just write us an email at support@epekworks.com and we'll do it for you.",
				InfoToCopy:
					'Please copy the following info and add it within your request email, so the support staff can proceed with deletion.',
				Warning:
					'Account deletion deletes all information related to your account including your projects and can not be reverted. Some information may still be kept for legally binding periods in line with EU GDPR regulations.',
			},
		},
		AvatarUpload: {
			Cancel: 'Cancel',
			Pick: 'Pick',
			Upload: 'Upload',
		},
		Legal: {
			PrivacyLinkLabel: 'Privacy Policy',
			TermsLinkLabel: 'Terms and Conditions',
		},
		CloneSample: {
			Description:
				'You can just view this example project or get a copy, which you can also edit, and make any changes to.',
		},
		Timeline: {
			TimelineStart: 'The Start',
			NoMilestonesTitle: "Let's get started!",
			NoMilestonesMessage: 'Press the blue  "+ Add New Milestone" button to start adding milestones!',
			AddButtonLabel: 'Add a milestone',
		},
		Project: {
			Timeline: {
				TimelineView: 'Timeline view',
				ColumnView: 'Column view',
				ListView: 'List view',
				Backlog: 'Backlog',
				Today: 'Today',
				ShowTasks: 'Show Tasks',
				TasksVisible: 'Tasks visible',
			},
			Item: {
				AddImagesFiles: 'Add images & files',
				AddLink: 'Add link',
			},
			Milestone: {
				Header: 'Milestone',
				Create: {
					Title: 'Add a Milestone',
					TitleLabel: 'Milestone title... (required)',
					DescriptionLabel: 'Description...',
					StartDateLabel: 'Start date',
					EndDateLabel: 'Due date',
					TagsLabel: 'Tags...',
					CreateButtonLabel: 'Add a Milestone',
					TasksHint: 'Once you create the milestone, you can add tasks here!',
				},
				Details: {
					Title: 'Milestone Details',
				},
				Clone: {
					Title: 'Clone Milestone',
					UnassignAssignees: 'Unassign tasks and subtasks with assignees',
					ResetStatuses: 'Reset all statuses to TODO',
					TasksToBacklog: 'Move cloned tasks to backlog',
					WithComments: 'Include comments',
				},
				Tasks: 'Tasks',
				Tags: 'Tags',
				Task: 'Add new task',
				ListOfTasks: 'List of tasks',
				NoTasks: 'This milestone has no tasks to tell',
				NoTasksHint:
					'Tasks are simply things you need to do in order to complete this milestone or goal. Think of them as tasks you would tell when you are done, e.g. "Publish my website", "update contact information", "send out the essay for review"',
				DateRange: 'Due date',
				StatusLabel: 'Status',
				MoreInfoLabel: 'Links',
				ImagesLabel: 'Images',
				StartDate: '(optional) Start Date',
				EndDate: 'Due',
				Duration: 'Duration',
			},
			Backlog: {
				Header: 'Backlog',
				Hint: 'Your Backlog contains all the tasks (things to do) which do not yet belong to a specific milestone. Think of it as a parking lot for ideas. Just add them here and later you can move them into specific milestones, or send them from the milestone back into the backlog!',
				MilestonePlanningHint:
					'You can plan your milestones by simply dragging a task from backlog to a milestone, or dragging and dropping tasks between milestones and the backlog. Go ahead, try it out!',
				Create: {
					Title: 'Add a Milestone',
					TitleLabel: 'Milestone title... (required)',
					DescriptionLabel: 'Description...',
					StartDateLabel: 'Start date',
					EndDateLabel: 'Due date',
					TagsLabel: 'Tags...',
					CreateButtonLabel: 'Add a Milestone',
					TasksHint: 'Once you create the milestone, you can add tasks here!',
				},
				Details: {
					Title: 'Say something about your backlog',
				},
				Tasks: 'Tasks',
				Task: 'Add new task',
				DateRange: 'Due date',
				StatusLabel: 'Status',
				MoreInfoLabel: 'Links',
				StartDate: '(optional) Start Date',
				EndDate: 'Due',
				Duration: 'Duration',
			},
			Project: {
				Create: {
					Title: 'New Project Name',
					Description: 'Project Description',
				},
				Clone: {
					Title: 'Clone Project',
					UnassignAssignees: 'Unassign tasks and subtasks with assignees',
					ResetStatuses: 'Reset all statuses to TODO',
					TasksToBacklog: 'Move all tasks to backlog',
					WithComments: 'Include comments',
				},
				AddNewProject: 'Add new project',
				Header: 'Project',
				NameLabel: 'Project Name',
				MilestoneList: 'All Milestones',
				SelectOpenMilestone: 'Select one of the open milestones',
			},
			Zoom: 'Scale:',
			Task: {
				Header: 'Task',
				Create: {
					Title: 'Add a Task',
					TitleLabel: 'Title... (required)',
					DescriptionLabel: 'Description...',
					StartDateLabel: 'Start date',
					EndDateLabel: 'Due',
					TagsLabel: 'Tags...',
				},
				Clone: {
					Title: 'Clone Task',
					UnassignAssignees: 'Unassign task and subtasks with assignees',
					ResetStatuses: 'Reset status(es) to TODO',
					TasksToBacklog: 'Move cloned task to backlog',
					WithComments: 'Include comments',
				},
				ToBacklog: 'Back to the Backlog...',
				MoveTask: 'Move to another Milestone...',
				CloneTask: 'Clone Task',
				DeleteTask: 'Delete Task',
				DateRange: 'Due',
				StatusLabel: 'Status',
				MoreInfoLabel: 'Links',
				AssigneeLabel: 'Assignee',
				Subtasks: 'Subtasks',
				Subtask: 'Add new subtask',
				ListOfSubtasks: 'List of subtasks',
				NoSubtasks: 'This task does not have any subtasks yet',
				NoSubtasksHint:
					'Subtasks are the details within the tasks e.g. If the task was "send out the essay for review", the subtasks could be "Make sure the formatting is fixed", "Add footer with page numbers", "Add references at the end!"',
				MoveHeader: 'Move: ',
				CurrentMilestone: 'From:',
				NewMilestone: 'To:',
			},
			Subtask: {
				Header: 'Subtask',
				CloneSubtask: 'Clone Subtask',
				DeleteSubtask: 'Delete Subtask',
				Create: {
					Title: 'Add new subtask',
					TitleLabel: 'Title... (required)',
					DescriptionLabel: 'Description...',
					StartDateLabel: 'Start date',
					EndDateLabel: 'Due',
					TagsLabel: 'Tags...',
					UrlLabel: 'URL',
				},
				Clone: {
					Title: 'Clone Subtask',
					UnassignAssignees: 'Unassign assignees',
					ResetStatuses: 'Reset status to TODO',
					WithComments: 'Include comments',
				},
				DateRange: 'Due',
				StatusLabel: 'Status',
				MoreInfoLabel: 'Links',
				AssigneeLabel: 'Assignee',
			},
		},
		Plan: {
			InviteUsers: 'Invite more people from your workspace collaborator setting screen!',
		},
		SignUp: {
			Title: 'Get Started with epek',
			Login: 'Log In',
			Register: 'SIGN UP',
			ResentVerification: 'Resend verification',
			PasswordRules:
				'Our password policy: minimum 8 characters, including numbers. We recommend a mix of upper and lowercase letters, numbers, and special characters.',
		},
		ResendVerification: {
			Title: 'Verify your account',
			Button: 'Send verification email',
		},
		SignUpSuccess: {
			Title: 'Verify email address',
			TextPartOne: 'We just sent you a verification email on the email-address: ',
			TextPartTwo:
				'. Please check your inbox, and verify your email-address. You should be able to log in afterwards.',
			SignupAgain: 'Sign up again',
		},
		Login: {
			Title: 'Welcome again',
			TitleSimple: 'Welcome',
			SignUp: 'Sign up',
			Login: 'Log In',
			LoginWithGoogle: 'Continue with Google',
			LoginWithMagic: 'Continue with Magic Link',
			TypePassword: 'Prefer to type in the password?',
			PreferMagicLink: 'Prefer Magic Links?',
			MagicEmailSentTitle: "Isn't this magical?",
			MagicEmailSentTextOne: "Your magic link is on it's way to ",
			MagicEmailSentTextTwo: 'Just click the link to log in and enjoy epek!',
			ForgotPassword: 'Forgot password?',
			CatchPhrase: 'Never lose sight of your goals!',
			NewToEpek: 'New to epek? Continue with Google or Magic Link to sign up and log in in one go!',
		},
		ForgotPassword: {
			Title: 'Password Reset Request',
			Text: "Please type in the email-address you signed up with, and we'll send you the link to reset your password.",
			Request: 'Send me the email',
		},
		ForgotPasswordSuccess: {
			Title: 'Password reset link sent!',
			Text: 'Your password reset link should be in your inbox just about now. We are here to help if you need more support!',
		},
		ResetPassword: {
			Title: 'Please type in a new password',
			NewPassword: 'NewPassword',
			ConfirmNewPassword: 'ConfirmNewPassword',
			Request: 'Update Password',
			PasswordShouldMatch: "Passwords don't match",
		},
		ResetPasswordSuccess: {
			Title: 'Success!',
			Text: 'Your password has been reset successfully. You can now log in to your workspace with your new password.',
		},
		VerifyEmail: {
			ErrorTitle: "That didn't work...",
			ErrorText: "Your account couldn't be confirmed, hopefully the following error message explains why:",
			SuccessTitle: 'Account confirmed!',
			SuccessText:
				'Thank you for clicking that link! Your email address has been verified and you are good to go.',
		},
		Payments: {
			Tabs: {
				Subscription: 'Subscription',
				CostCalculator: 'Cost Calculator',
				PaymentInfo: 'Payment Information',
				Invoices: 'Invoices',
			},
			Subscribe: 'Upgrade',
			Unsubscribe: 'Downgrade',
			UnsubscribePrompt: {
				Title: 'Are you sure you want to downgrade from Epek TEAM plan?',
				Content:
					'Downgrading from Epek TEAM plan will convert all additional editors to viewers at the end of current billing period, leaving ONLY three workspace members as editors. You can always change editor rights any time you wish.',
			},
			BillingPeriod: 'Current billing period runs from <1>{{from}}</1> to <3>{{to}}</3>',
			Subscription: {
				Plan: 'Epek TEAM',
				PlanDescription:
					'Unlimited viewers, {{freeEditors}} editors + ${{pricePerEditor}} per each additional editor',
				CancelledInfo: 'Subscription is cancelled. And will end at the end of the current period.',
				BillingEndDate: 'Billing ended at {{endDate}}',
			},
			EstimatedBill: 'Estimated Monthly bill ${{value}}',
			MissingPaymentMethod: 'Please add a payment method first',
			Errors: {
				PaymentMethodFetch: 'Error retrieving payment information',
				PaymentMethodChange: 'Error changing payment method',
				PaymentMethodAdd: 'Error adding payment method',
				InvoicesFetch: 'Error retrieving invoice list',
				BillingDetailsChange: 'Error updating billing details',
			},
			PaymentMethod: 'Payment Method',
			PaymentMethodInfo: '{{brand}} ending with {{last4}}',
			PaymentMethodEdit: 'Change payment method',
			PaymentMethodAdd: 'Add payment method',
			PaymentMethodUpdated: 'Successfully updated the payment method',
			BillingDetailsUpdate: 'Update',
			PersonalUse: 'Personal use',
			BillingName: 'Billing name',
			BillingAddress: 'Billing address',
			BillingTaxNumber: 'VAT number',
			BillingCountry: 'Country',
			BillingDetailsChanged: 'Successfully updated the billing details',
			InvoiceTable: {
				createdAt: 'Date',
				status: 'Status',
				total: 'Total',
				pdfUrl: 'Download PDF',
			},
		},
		GenericPageError: {
			Title: 'Something went wrong',
			Text: 'Please try reloading the page or return to home page',
			Button: 'Reload',
		},
		Messages: {
			URLCopy: {
				Done: 'link has been copied to clipboard',
			},
			History: {
				create: 'created',
				update: 'updated',
				delete: 'deleted',
				clone: 'cloned',
				move: 'moved',
				assign: 'changed assignee',
				status: 'changed status',
				public: 'made public',
				not_public: 'made private',
			},
			Filters: {
				ByStatusError: 'Turn off Sort By Due Dates to change task priorities',
			},
			Subtask: {
				Created: 'Subtask has been created',
				CreatedError: 'Failed to create subtask',
				Cloned: 'Subtask has been cloned',
				ClonedError: 'Failed to clone subtask',
				Updated: 'Subtask has been updated',
				UpdatedError: 'Failed to update subtask',
				Deleted: 'Subtask has been removed',
				DeletedError: 'Failed to remove subtask',
				NoTitleError: 'Subtask title is required',
				NotFound: 'Could not find subtask',
			},
			Task: {
				Moved: 'Task has been moved',
				MovedError: 'Failed to move task',
				Created: 'Task has been created',
				CreatedError: 'Failed to create task',
				Cloned: 'Task has been cloned',
				ClonedError: 'Failed to clone task',
				Updated: 'Task has been updated',
				UpdatedError: 'Failed to update task',
				Deleted: 'Task has been removed',
				DeletedError: 'Failed to remove task',
				NoTitleError: 'Task title is required',
				SameMilestone: 'Task is already in the target milestone',
				NotFound: 'Could not find task',
			},
			Milestone: {
				Created: 'Milestone has been created',
				CreatedError: 'Failed to create milestone',
				Cloned: 'Milestone has been cloned',
				ClonedError: 'Failed to clone milestone',
				Updated: 'Milestone has been updated',
				UpdatedError: 'Failed to update milestone',
				Deleted: 'Milestone has been removed',
				DeletedError: 'Failed to remove milestone',
				NoTitleError: 'Milestone title is required',
				NotFound: 'Could not find milestone',
			},
			Folder: {
				Created: 'New Folder has been added at the bottom of the list',
			},
			Project: {
				Created: 'Project has been created',
				CreatedError: 'Failed to create project',
				Cloned: 'Project has been cloned',
				ClonedError: 'Failed to clone project',
				Updated: 'Project has been updated',
				UpdatedError: 'Failed to update project',
				Deleted: 'Project has been removed',
				DeletedError: 'Failed to remove project',
				NoTitleError: 'Project title is required',
				NotFoundError: "We couldn't find this project",
				ViewerMessage:
					'You only have VIEWER rights on this project. Please talk to the workspace owner or admins to grant you editing rights, if needed.',
				MashupMessage:
					"Note: Mix/mashup view is view-only, so you won't be able to edit the milestones or items within.",
			},
			UserDetails: {
				Updated: 'Your details have been updated',
				UpdatedError: 'Failed to update your details',
				NoTitleError: 'Your name is required',
			},
			UserWorkspace: {
				Invite: 'Your invitations will be sent right away',
				InviteWithoutEmails: 'Please add the invitee email addresses',
				Leave: 'You have left that workspace',
				LeaveError: 'Failed to leave the workspace',
				LeaveOwnOrgError: "You can't leave your own workspace",
				Updated: 'Your workspace has been updated',
				UpdatedError: 'Failed to update workspace name or logo',
				NoTitleError: 'Workspace name is required',
			},
		},
	},
};

export default translations;
