import React, { Dispatch, ReactNode } from 'react';
import { reducer } from './reducer';
import { IUserAction, IUserState } from './constants';
import api from '../../api';
import {
	deleteUser,
	setActiveOrganization,
	setDueDateFilter,
	setOrganizations,
	setStatusFilter,
	setUser,
	setUserDetails,
	setUserLoaded,
} from './actions';
import { AuthUser } from '../../types/AuthUser';
import { ActionsMap } from '../../types/ActionType';
import { UserDetails } from '../../types/UserDetails';
import { Organization } from '../../types/Organization';

const initialState: IUserState = {
	isOnBoarded: true,
	userLoaded: false,
	organizations: [],
	filters: {
		byStatus: localStorage.getItem('FILTER_BY_STATUS') === '1',
		byDueDate: localStorage.getItem('FILTER_BY_DUE_DATE') === '1',
	},
};

export const AppUserContext = React.createContext<[IUserState, ActionsMap, Dispatch<IUserAction>]>([
	initialState,
	{},
	(action: IUserAction) => {},
]);

interface Props {
	children: ReactNode;
}

const ACTIVE_ORGANIZATION = 'ORGANIZATION';

export const UserProvider = ({ children }: Props) => {
	const [state, dispatch] = React.useReducer(reducer, {
		...initialState,
	});

	const contextValue: [IUserState, ActionsMap, Dispatch<IUserAction>] = React.useMemo(
		() => [
			state,
			{
				setUser: async (user: AuthUser) => {
					dispatch(setUser(user));
				},
				deleteUser: async () => {
					dispatch(deleteUser());
				},
				fetchOrganizations: async () => {
					const { data } = await api.organization.getAll();
					dispatch(setOrganizations(data));
				},
				getActiveOrganization: async (fetch?: boolean) => {
					let organizations: Organization[] = state.organizations || [];
					if (fetch) {
						const { data } = await api.organization.getAll();
						dispatch(setOrganizations(data));
						organizations = data;
					}
					if (!organizations?.length) {
						dispatch(setActiveOrganization());
					}
					if (organizations && organizations.length) {
						let orgId = localStorage.getItem(ACTIVE_ORGANIZATION);
						let organization = organizations.find((o: Organization) => o.id === orgId);
						if (!organization) {
							organization = organizations[0];
							localStorage.setItem(ACTIVE_ORGANIZATION, organization.id);
							api.organization.setActiveOrganization(organization.id);
						}
						dispatch(setActiveOrganization(organization));
					}
				},
				setActiveOrganization: async (organizationId: string, fetch?: boolean) => {
					let organizations: Organization[] = state.organizations || [];
					if (fetch) {
						const { data } = await api.organization.getAll();
						dispatch(setOrganizations(data));
						organizations = data;
					}
					localStorage.setItem(ACTIVE_ORGANIZATION, organizationId);
					if (organizations) {
						let organization = organizations.find(o => o.id === organizationId);
						dispatch(setActiveOrganization(organization));
						api.organization.setActiveOrganization(organizationId);
					}
				},
				refreshOrganization: async () => {
					const { data } = await api.organization.getAll();
					dispatch(setOrganizations(data));
					const organizations = data;
					if (organizations) {
						let org = organizations.find(o => o.id === state?.organization?.id);
						if (org) {
							dispatch(setActiveOrganization(org));
						}
					}
				},
				getUserDetails: async () => {
					const { data } = await api.user.details();
					dispatch(setUserDetails(data));
				},
				setUserLoaded: async (loaded?: boolean) => {
					dispatch(setUserLoaded(loaded));
				},
				updateUserDetails: async (displayName: string, logo: string, emailNotifications?: boolean) => {
					const { data } = await api.user.updateDetails(displayName, logo, emailNotifications);
					dispatch(setUserDetails(data));
				},
				updateBillingDetails: async (billingDetails: Omit<UserDetails, 'displayName' | 'logo'>) => {
					const { data } = await api.user.updateBillingDetails(billingDetails);
					dispatch(setUserDetails(data));
				},
				updateStatusFilter: async (byStatus: boolean) => {
					dispatch(setStatusFilter(byStatus));
				},
				updateDueDateFilter: async (byDueDate: boolean) => {
					dispatch(setDueDateFilter(byDueDate));
				},
			},
			dispatch,
		],
		[state, dispatch]
	);
	return <AppUserContext.Provider value={contextValue}>{children}</AppUserContext.Provider>;
};

export const useUserContext = () => React.useContext(AppUserContext);

// export { UserContext, UserProvider, useUserContext };
