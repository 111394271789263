import { AxiosResponse } from 'axios';
import api from './base';
import { Milestone, MilestoneClone } from '../types/Milestone';
import { ProjectFilters } from '../types/Project';
import { EntryHistory } from '../types/EntryHistory';

export const create = (projectId: string, milestone: Omit<Milestone, 'id'>): Promise<AxiosResponse<Milestone>> =>
	api.put('/milestone', { projectId, ...milestone });

export const remove = (milestoneId: string): Promise<AxiosResponse<Milestone>> =>
	api.delete(`/milestone/${milestoneId}`);

export const update = (
	milestoneId: string,
	milestone: Omit<Milestone, 'id'>,
	adjust = false
): Promise<AxiosResponse<Milestone>> => api.post(`/milestone/${milestoneId}`, { ...milestone, adjust });

export const get = (milestoneId: string, filters?: ProjectFilters): Promise<AxiosResponse<Milestone>> =>
	api.get(`/milestone/${milestoneId}`, {
		params: filters,
	});

export const history = (milestoneId: string): Promise<AxiosResponse<EntryHistory[]>> =>
	api.get(`/milestone/${milestoneId}/history`);

export const clone = (milestoneId: string, parameters?: MilestoneClone): Promise<AxiosResponse<Milestone>> =>
	api.post(`/milestone/${milestoneId}/clone`, {
		withStories: true,
		...parameters,
	});

export const moveUnfinished = (milestoneId: string): Promise<AxiosResponse<Milestone>> =>
	api.post(`/milestone/${milestoneId}/moveUnfinished`);
