import { mode, darken, whiten } from '@chakra-ui/theme-tools';
import { StyleOptions } from '../types';

export const InputStyles = {
	baseStyle: {},
	sizes: {},
	variants: {
		form: {
			outline: 'none',
			border: 0,
			field: {
				p: '5px 10px',
				bg: 'transparent',
			},
		},
		filled: (props: StyleOptions) => ({
			field: {
				border: '2px solid',
				borderColor: 'transparent',
				bg: mode('gray.25', 'gray.700')(props),
				_hover: {
					bg: mode('gray.50', 'gray.800')(props),
				},
				_focus: {
					bg: mode('gray.50', 'gray.800')(props),
				},
			},
			addon: {
				border: '2px solid',
				borderColor: 'transparent',
				bg: mode(darken('gray.400', 20), whiten('gray.600', 20))(props),
			},
			date: {
				':-webkit-calendar-picker-indicator': {
					fill: 'red',
				},
			},
		}),
		light: (props: StyleOptions) => ({
			field: {
				border: '0',
				bg: mode('white.100', 'gray.600')(props),
				_hover: {
					bg: mode('white', 'gray.600')(props),
				},
				_focus: {
					bg: mode('white', 'gray.600')(props),
				},
			},
		}),
		tags: (props: StyleOptions) => ({
			minHeight: 'auto',
			field: {
				border: '0',
				lineHeight: '12px',
				fontSize: '12px',
				display: 'inline-flex',
				alignItems: 'center',
				p: '5px 6px',
				height: 'auto',
				bg: mode('gray.50', 'gray.300')(props),
				_hover: {
					bg: mode('gray.50', 'gray.400')(props),
				},
				_focus: {
					bg: mode('gray.50', 'gray.400')(props),
				},
			},
		}),
		tagsSecondary: (props: StyleOptions) => ({
			minHeight: 'auto',
			field: {
				lineHeight: 'md',
				fontSize: 'md',
				display: 'inline-flex',
				alignItems: 'center',
				p: '5px 6px',
				height: 'auto',
				bg: mode('white', 'gray.300')(props),
				_hover: {
					bg: mode('white', 'gray.400')(props),
				},
				_focus: {
					bg: mode('white', 'gray.400')(props),
				},
			},
		}),
	},
	defaultProps: {},
};
