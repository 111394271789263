import { AxiosResponse } from 'axios';
import api from './base';
import { UserDetails } from '../types/UserDetails';
import { EpekNotification } from '../types/Notification';

export const details = (): Promise<AxiosResponse<UserDetails>> => api.get('/user/details');

export const updateDetails = (
	displayName: string,
	logo: string,
	emailNotifications?: boolean
): Promise<AxiosResponse<UserDetails>> => api.post('/user/details', { displayName, logo, emailNotifications });

export const updateBillingDetails = (
	billingDetails: Omit<UserDetails, 'displayName' | 'logo'>
): Promise<AxiosResponse<UserDetails>> => api.post('/user/details/billing', billingDetails);

export const unsubscribeNewsletter = (email: string): Promise<AxiosResponse<void>> =>
	api.post('/user/unsubscribe', { email });

export const watchEntry = (
	type: 'organization' | 'project' | 'milestone' | 'story' | 'task',
	id: string
): Promise<AxiosResponse<void>> => api.get(`/user/watch/${type}/${id}`);

export const notifications = (offset?: number): Promise<AxiosResponse<{ data: EpekNotification[]; total: string }>> =>
	api.get(`/user/notifications${offset ? `?offset=${offset}` : ''}`);

export const activateAppSumo = (code: string): Promise<AxiosResponse<UserDetails>> =>
	api.post(`/appSumo/${code}/activate`, {});
