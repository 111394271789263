import * as UserActionTypes from './constants';
import { IUserAction, IUserState } from './constants';

export const reducer = (state: IUserState, action: IUserAction) => {
	switch (action.type) {
		case UserActionTypes.SET_USER:
			return { ...state, user: action.payload.user };
		case UserActionTypes.SET_ACTIVE_ORGANIZATION:
			return {
				...state,
				organization: action.payload.organization,
				isOnBoarded: !!action.payload.organization,
			};
		case UserActionTypes.SET_ORGANIZATIONS:
			return {
				...state,
				organizations: action.payload.organizations,
			};
		case UserActionTypes.SET_USER_DETAILS:
			return { ...state, details: action.payload.userDetails };
		case UserActionTypes.DELETE_USER:
			return { ...state, user: undefined };
		case UserActionTypes.SET_USER_LOADED:
			return {
				...state,
				userLoaded: action.payload.userLoaded,
			};
		case UserActionTypes.SET_STATUS_FILTER:
			return {
				...state,
				filters: { ...state.filters, byStatus: action.payload.byStatus },
			};
		case UserActionTypes.SET_DUE_DATE_FILTER:
			return {
				...state,
				filters: { ...state.filters, byDueDate: action.payload.byDueDate },
			};
	}
	return state;
};
