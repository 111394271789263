import React, { useEffect, useState } from 'react';
import { useUserContext } from '../context/UserContext/UserContext';
import api from '../api';
import { Modal } from './UI/Modal/Modal';
import { Flex, Heading, Text, Button } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const AppSumoActivation = () => {
	const [{ user }, { getUserDetails }] = useUserContext();
	const { t } = useTranslation();
	const [loading, setLoading] = useState(true);
	const [success, setSuccess] = useState(false);
	const [error, setError] = useState('');
	const [visible, setVisible] = useState(true);
	const code = user?.appSumo;

	useEffect(() => {
		const activateAppSumo = async () => {
			if (code) {
				try {
					await api.user.activateAppSumo(code);
					await getUserDetails();
					setSuccess(true);
					setLoading(false);
				} catch (e) {
					setError(e.response.data);
					setLoading(false);
				}
			}
		};
		activateAppSumo();
		// eslint-disable-next-line
	}, []);
	return (
		<Modal isOpen={visible} onClose={() => {}} close={false} size="md" minW="40%">
			<Flex flexDirection="column" alignItems="center" flex="1" padding="16px 12px">
				{success && !loading && (
					<>
						<Heading as="h2" fontSize="2xl" textAlign="center">
							{t('AppSumo.ThankYou')}
						</Heading>
						<Text mt="20px" fontSize="xl" textAlign="center">
							{t('AppSumo.ThankYouDescription')}
						</Text>
					</>
				)}
				{error && !loading && (
					<>
						<Heading as="h2" fontSize="2xl" textAlign="center">
							Couldn't activate you AppSumo code
						</Heading>
						{error === 'already_activated' && (
							<Text
								mt="20px"
								fontSize="xl"
								textAlign="center"
								dangerouslySetInnerHTML={{
									__html: t('AppSumo.Errors.AlreadyActivated', { contact: 'support@epekworks.com' }),
								}}
							/>
						)}
						{error === 'invalid_code' && (
							<Text mt="20px" fontSize="xl">
								{t('AppSumo.Errors.InvalidCode')}
							</Text>
						)}
					</>
				)}
				{!loading && (
					<Button mt="4" onClick={() => setVisible(false)}>
						{t('Common.Continue')}
					</Button>
				)}
			</Flex>
		</Modal>
	);
};
