export const FormErrorMessageStyles = {
	baseStyle: {},
	sizes: {},
	variants: {
		formControlItem: {
			color: 'brand.danger.primary',
			marginTop: '20px',
			position: 'relative',
		},
	},
	defaultProps: {
		default: 'formControlItem',
	},
};
