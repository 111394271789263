import Amplify, { Auth } from 'aws-amplify';
import { CognitoUser } from '@aws-amplify/auth';
import { CognitoUserPool } from 'amazon-cognito-identity-js';
import { magicLinkKey } from '../Config';
import { v4 as uuidv4 } from 'uuid';

let userSignedIn = false;

const configuration = {
	Auth: {
		region: process.env.REACT_APP_AWS_REGION,
		userPoolId: process.env.REACT_APP_AWS_USER_POOL_ID,
		userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID,
		clientMetadata: {
			host: `${window.location.protocol}//${window.location.host}`,
		},
		oauth: {
			domain: `auth.${window.location.host}`,
			redirectSignIn: `${window.location.protocol}//${window.location.host}/login`,
			redirectSignOut: `${window.location.protocol}//${window.location.host}`,
			redirectUri: `${window.location.protocol}//${window.location.host}`,
			scope: ['openid', 'profile', 'email'],
			responseType: 'token', // or 'token', note that REFRESH token will only be generated when the responseType is code
		},
	},
};

Amplify.configure(configuration);

export const login = (email: string, password: string): Promise<CognitoUser> =>
	Auth.signIn(email.toLowerCase(), password);

export const register = (email: string, password: string, clientMetadata?: any) =>
	Auth.signUp({
		username: email.toLowerCase(),
		password,
		attributes: { email: email.toLowerCase() },
		...(clientMetadata ? { clientMetadata } : {}),
	});

export const resendVerification = (email: string) => Auth.resendSignUp(email.toLowerCase());

export const resetPassword = (email: string) => Auth.forgotPassword(email.toLowerCase());

export const confirmPasswordReset = (email: string, code: string, newPassword: string) =>
	Auth.forgotPasswordSubmit(email.toLowerCase(), code, newPassword);

export const verifyEmail = (email: string, code: string) => Auth.confirmSignUp(email, code);

export const logout = async () => {
	await Auth.signOut();
};

export const loginWithGoogle = (): Promise<any> =>
	// @ts-ignore
	Auth.federatedSignIn({ provider: 'Google' });

export const loginWithMagicLink = async (email: string) => {
	try {
		const cognitoUser = await Auth.signIn(email);
		const { Session, username } = JSON.parse(JSON.stringify(cognitoUser));

		localStorage.setItem(magicLinkKey, JSON.stringify({ Session, username }));
		return cognitoUser;
	} catch (e) {
		if (e.code === 'UserNotFoundException') {
			await forceAccountCreation(email);
			const cognitoUser = await Auth.signIn(email);

			localStorage.setItem(magicLinkKey, JSON.stringify(cognitoUser));
			return cognitoUser;
		}
	}
};

const forceAccountCreation = async (email: string) => {
	return Auth.signUp({
		username: email.toLowerCase(),
		password: uuidv4().replace('-', ''),
		attributes: { email: email.toLowerCase() },
		clientMetadata: {
			...configuration.Auth.clientMetadata,
			forceCreation: 'true',
		},
	});
};

export const getCognitoUserFromStorage = async () => {
	try {
		const userData = JSON.parse(localStorage.getItem(magicLinkKey)!);
		const pool = new CognitoUserPool({
			UserPoolId: process.env.REACT_APP_AWS_USER_POOL_ID!,
			ClientId: process.env.REACT_APP_AWS_USER_POOL_WEB_CLIENT_ID!,
		});
		const user = new CognitoUser({
			...userData,
			Username: userData.username,
			Pool: pool,
		});

		// @ts-ignore
		user.Session = userData.Session;
		return user;
	} catch (e) {
		throw Error('No user to challange');
	}
};

export const loginChallange = async (code: string) => {
	try {
		let cognitoUser = await getCognitoUserFromStorage();
		await Auth.sendCustomChallengeAnswer(cognitoUser, code);
		// This will throw an error if the user is not yet authenticated:
		await Auth.currentSession();
	} catch (e) {
		console.log('Apparently the user did not enter the right code');
		throw e;
	}
};

export const getAccessJwtToken = async () => {
	// if (userSignedIn) {
	// Auth.currentSession() checks if token is expired and refreshes with Cognito if needed automatically
	const session = await Auth.currentSession();
	return session.getIdToken().getJwtToken();
	// }
};

export const setSignedIn = (signedIn: boolean) => {
	// eslint-disable-next-line
	userSignedIn = signedIn;
};

export const ConfiguredAuth = Auth;
