import axios from 'axios';
import { apiUri } from '../Config';
import { getAccessJwtToken } from '../utils/cognitoHandler';

const axiosInstance = axios.create({
	baseURL: apiUri,
});

// not sure if this is needed on every request but haven't think of a better solution
// maybe we can add token to every request sent but that would be even more complicated
axiosInstance.interceptors.request.use(async config => {
	try {
		const token = await getAccessJwtToken();
		if (token !== null && token !== undefined && token !== '' && config?.headers) {
			config.headers.Authorization = `Bearer ${token}`;
		}
	} catch (exception) {
		console.log('Exception: ', (exception as string).toString());
	}

	return config;
});

export default axiosInstance;
