import { StyleOptions } from '../types';
import { mode } from '@chakra-ui/theme-tools';

export const TextareaStyles = {
	baseStyle: {},
	sizes: {},
	variants: {
		form: {
			p: '5px 10px',
			outline: 'none',
			bg: 'transparent',
			border: 0,
		},
		autoSizeForm: (props: StyleOptions) => ({
			p: '5px 10px',
			outline: 'none',
			minHeight: '30px',
			overflow: 'hidden',
			border: 0,
			bg: mode('gray.25', 'gray.700')(props),
			_placeholder: {
				color: mode('gray.200', 'gray.300')(props),
			},
			_hover: {
				bg: mode('gray.50', 'gray.800')(props),
			},
			_focus: {
				bg: mode('gray.50', 'gray.800')(props),
			},
		}),
		light: (props: StyleOptions) => ({
			border: '0',
			bg: mode('white', 'gray.600')(props),
			_hover: {
				bg: mode('white', 'gray.600')(props),
			},
			_focus: {
				bg: mode('white', 'gray.600')(props),
			},
		}),
	},
	defaultProps: {},
};
