import React, { Component } from 'react';
import { ErrorMessage } from './ErrorMessage';
import { captureException } from '@sentry/browser';

interface State {
	error?: boolean;
}

export class ErrorBoundary extends Component<{}, State> {
	state: State = {};

	static getDerivedStateFromError(error: Error & { code?: string }) {
		if (error.name === 'ChunkLoadError' || error.code === 'CSS_CHUNK_LOAD_FAILED') {
			window.location.reload();
			return;
		}
		console.warn(error);
		captureException(error);
		// Update state so the next render will show the fallback UI.
		return { error: true };
	}

	render() {
		if (this.state.error) {
			return <ErrorMessage />;
		}
		return this.props.children;
	}
}
