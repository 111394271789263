export const FormLabelStyles = {
	baseStyle: {},
	sizes: {},
	variants: {
		formControlItem: {
			backgroundColor: 'transparent',
			display: 'inline-flex',
			position: 'absolute',
			zIndex: 1,
			left: '10px',
			top: '-10px',
		},
	},
	defaultProps: {},
};
