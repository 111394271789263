import { mode } from '@chakra-ui/theme-tools';
import { StyleOptions } from '../types';

export const TooltipStyles = {
	baseStyle: {},
	sizes: {},
	variants: {
		primary: (props: StyleOptions) => ({
			bg: mode('gray.50', 'gray.600')(props),
			color: mode('gray.600', 'gray.100')(props),
			'--popper-arrow-bg': mode('#f7f7f7', '#333333')(props),
			borderRadius: '5px',
			fontSize: '14px',
			p: '15px 20px',
		}),
	},
	defaultProps: {
		variant: 'primary',
	},
};
