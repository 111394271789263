import { mode } from '@chakra-ui/theme-tools';
import { StyleOptions } from '../types';

export const MenuStyles = {
	baseStyle: (props: StyleOptions) => ({
		button: {},
		list: {},
		item: {
			py: '0.4rem',
			px: '0.8rem',
			transitionProperty: 'background',
			transitionDuration: 'ultra-fast',
			transitionTimingFunction: 'ease-in',
			_hover: {
				// backgroundColor: mode(darken('gray.50', 10), whiten('gray.500', 20))(props),
			},
			_focus: {
				bg: mode('gray.50', 'gray.500')(props),
			},
			_expanded: {
				bg: mode('gray.50', 'gray.500')(props),
			},
			_disabled: {
				opacity: 0.4,
				cursor: 'not-allowed',
			},
		},
		groupTitle: {},
		command: {},
		divider: {},
	}),
	sizes: {},
	variants: {},
};
