import React from 'react';
import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth, Hub } from 'aws-amplify';
import { HubCapsule } from '@aws-amplify/core';
import { useUserContext } from './UserContext';
import { appSumoCodeKey, tokenKey } from '../../Config';
import { ConfiguredAuth, setSignedIn, logout } from '../../utils/cognitoHandler';

export const AuthHandler = () => {
	const [, { setUser, deleteUser, getUserDetails, getActiveOrganization, setUserLoaded }] = useUserContext();

	const listenOnUserAuth = async ({ payload: { event, data } }: HubCapsule) => {
		const { username, signInUserSession } = data || {};
		const jwtToken = signInUserSession?.getIdToken().getJwtToken();
		switch (event) {
			case 'signIn':
				localStorage.setItem(tokenKey, jwtToken);
				const appSumo = localStorage.getItem(appSumoCodeKey);
				if (appSumo) {
					localStorage.removeItem(appSumoCodeKey);
				}
				await setUser({
					username,
					jwtToken,
					expirationAt: new Date(signInUserSession.getIdToken().getExpiration() * 1000),
					appSumo,
				});
				await getUserDetails();
				await getActiveOrganization(true);
				setSignedIn(true);
				return;
			case 'signOut':
				// TODO: add a proper fix. Without a full reload GraphQL still has the old cache
				localStorage.setItem(tokenKey, '');
				deleteUser();
				setSignedIn(false);
				return;
		}
	};

	const updateStateWithToken = async (session: CognitoUserSession) => {
		const { username } = await ConfiguredAuth.currentUserInfo();
		const jwtToken = session.getIdToken().getJwtToken();
		localStorage.setItem(tokenKey, jwtToken);
		await setUser({
			username,
			jwtToken,
			expirationAt: new Date(session.getIdToken().getExpiration() * 1000),
		});
		getUserDetails();
		getActiveOrganization(true);
		setSignedIn(true);
	};

	// logout user on return if he doesnt have up to date token
	const onVisibilityChanged = () => {
		if (!document.hidden) {
			Auth.currentSession()
				.then(session => {
					if (session.isValid()) {
					}
				})
				.catch(() => {
					logout();
				});
		}
	};

	React.useEffect(() => {
		Auth.currentSession()
			.then(session => {
				if (session.isValid()) {
					updateStateWithToken(session);
					setUserLoaded(true);
					return;
				}
			})
			.catch(() => setUserLoaded(true));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	React.useEffect(() => {
		Hub.listen('auth', listenOnUserAuth);
		document.addEventListener('visibilitychange', onVisibilityChanged);
		window.addEventListener('focus', onVisibilityChanged);

		return () => {
			Hub.remove('auth', listenOnUserAuth);
			document.removeEventListener('visibilitychange', onVisibilityChanged);
			window.removeEventListener('focus', onVisibilityChanged);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return null;
};
