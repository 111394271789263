export const FormControlStyles = {
	baseStyle: {},
	sizes: {},
	variants: {
		formControlItem: {
			marginTop: '20px',
			position: 'relative',
		},
		formClean: {
			marginTop: '20px',
			position: 'relative',
		},
	},
	defaultProps: {},
};
