import React, { ReactElement, ReactNode } from 'react';
import {
	Modal as ChakraModal,
	ModalOverlay,
	ModalContent,
	ModalHeader,
	ModalCloseButton,
	ModalBody,
	Flex,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';

type Props = {
	header?: string | ReactNode;
	isOpen: boolean;
	icon?: React.FC | ReactElement;
	onClose: () => void;
	children?: ReactNode;
	paddingX?: string;
	close?: boolean;
	preventScroll?: boolean;
	minW?: string;
	size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
};

export const Modal = ({
	header,
	isOpen,
	onClose,
	icon,
	children,
	minW = '75%',
	paddingX = '20px',
	close = true,
	preventScroll = false,
	size = 'xl',
}: Props) => {
	const headerColor = useColorModeValue('gray.200', 'gray.500');
	const frameColor = useColorModeValue('white', 'gray.600');
	return (
		<ChakraModal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose} size={size}>
			<ModalOverlay />
			<ModalContent
				bg={frameColor}
				minW={minW}
				w="100%"
				mx={{ base: 0, md: '40px' }}
				borderRadius="0"
				maxHeight="85vh"
				height={{ base: 'auto', md: 'auto' }}
			>
				{!!header && (
					<ModalHeader bg={headerColor} borderTopRadius="8px">
						<Flex flexDirection="row" alignItems="center">
							{icon}
							<Text ml="5px">{header}</Text>
						</Flex>
					</ModalHeader>
				)}
				{close && <ModalCloseButton top="3" />}
				<ModalBody
					my="20px"
					px={{ base: 0, md: paddingX }}
					py="0"
					width="100%"
					overflowY={!preventScroll ? 'auto' : 'initial'}
					display="flex"
					flexDirection="column"
				>
					{children}
				</ModalBody>
			</ModalContent>
		</ChakraModal>
	);
};
